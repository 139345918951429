// src/stores/counterStore.js
import { makeAutoObservable } from "mobx";

class QuantitativeReasoningStore {
  greModuleResponses = [];
  greSections = [];
  moduleId = "";
  selectedSectionId = null;
  selectedSectionName = "";
  questionType = "";
  currentSectionId = "";
  currentModuleQuestions = [];
  selectedQuestionId = "";
  questionTypeId = "";
  questionTypes = [];
  questionCategory = [];
  selectedQuantitativeQuestionType = "";
  payload = {
    title: "",
    passage: "",
    description: "",
    question: "",
    instructions: "",
    idealAnswer: "",
    explanation: "",
    notes: [],
    images: {
    },
    blanks: [
      {
        blankName: "",
        answers: [],
      },
    ],
    quantity: [],
    moduleId: "",
    sectionId: "",
    questionContentTypeId: "",
    questionContentDomainId: "",
    questionTypeId: "",
    questionDifficultyLevel: 0,
    additionalProps: {
    },
  };
  currentClickedQuestion = {};
  updatedDataGre = [];
  domains = [];
  contentType = [];

  constructor() {
    makeAutoObservable(this);
    this.loadPersistedData();
  }

  setDomains = (data) => {
    this.domains = data;
  };

  setContentType = (data) => {
    this.contentType = data;
  };

  setQuestionType(data) {
    this.questionType = data;
  }
  setQuestionTypes = (questionTypes) => {
    this.questionType = questionTypes;
  };
  setGreSections(data) {
    this.greSections = data;
  }
  setSelectedQuantitativeQuestionType(data) {
    this.selectedQuantitativeQuestionType = data;
  }
  setModuleId(data) {
    this.moduleId = data;
  }
  setSelectedSectionName(name) {
    this.selectedSectionName = name;
  }
  setQuestionCategory(questionTypes) {
    this.questionCategory = questionTypes;
  }
  setUpdatedDataGre(data) {
    this.updatedDataGre = data;
  }
  setCurrentsectionId(data) {
    this.currentSectionId = data;
  }
  setQuestionTypeId(data) {
    this.questionTypeId = data;
  }
  setCurrentModuleQuestions(moduleQns) {
    this.currentModuleQuestions = moduleQns;
  }
  setPayload(data) {
    this.payload = data;
  }

  setSelectedSectionId(id) {
    this.selectedSectionId = id;
  }

  setGreModuleResponses(data) {
    this.greModuleResponses = data;
  }
  setCurrentClickedQuestion = (item) => {
    this.currentClickedQuestion = item;
  };
  setSelectedQuestionId = (id) => {
    this.selectedQuestionId = id;
  };
  setQuestionTypes = (data) => {
    this.questionTypes = data;
  };
  setResetPayload = () => {
    this.payload = {
      title: "",
      passage: "",
      description: "",
      question: "",
      instructions: "",
      idealAnswer: "",
      explanation: "",
      notes: [],
      images: {
      },
      blanks: [
        {
          blankName: "",
          answers: [],
        },
      ],
      quantity: [],
      moduleId: "",
      sectionId: "",
      questionContentTypeId: "",
      questionContentDomainId: "",
      questionTypeId: "",
      questionDifficultyLevel: 0,
      additionalProps: {
      },
    };
  };
  savePersistedData() {
    localStorage.setItem(
      "selectedSection",
      JSON.stringify({
        id: this.selectedSectionId,
        name: this.selectedSectionName,
      })
    );
  }

  // Method to load persisted data from localStorage
  loadPersistedData() {
    const persistedData = localStorage.getItem("selectedSection");
    if (persistedData) {
      const parsedData = JSON.parse(persistedData);
      this.selectedSectionId = parsedData.id;
      this.selectedSectionName = parsedData.name;
    }
  }
}

const quantitativeReasoningStore = new QuantitativeReasoningStore();
export default quantitativeReasoningStore;

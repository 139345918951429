// src/stores/counterStore.js
import { makeAutoObservable } from "mobx";

class ReadingStore {
  selectedSectionId = null;
  selectedSectionName = "";
  editMode = false;
  questionsCategory = [];
  multipleSingle = false;
  sections = [];
  formData = {
    title: "",
    content: "",
    questions: [],
  };

  constructor() {
    makeAutoObservable(this);
    this.loadPersistedData();
  }

  setSections(data) {
    this.sections = data;
  }

  setQuestionsCategory(data) {
    this.questionsCategory = data;
  }

  setEditMode(bool) {
    this.editMode = bool;
  }

  setFormData(newFormData) {
    this.formData = newFormData;
  }

  setSelectedSectionName(name) {
    this.selectedSectionName = name;
  }

  setSelectedSectionId(id) {
    this.selectedSectionId = id;
  }
  setMultipleSingle(value) {
    this.multipleSingle = value;
  }
  // Method to persist data to localStorage
  savePersistedData() {
    localStorage.setItem(
      "selectedSection",
      JSON.stringify({
        id: this.selectedSectionId,
        name: this.selectedSectionName,
      })
    );
  }

  // Method to load persisted data from localStorage
  loadPersistedData() {
    const persistedData = localStorage.getItem("selectedSection");
    if (persistedData) {
      const parsedData = JSON.parse(persistedData);
      this.selectedSectionId = parsedData.id;
      this.selectedSectionName = parsedData.name;
    }
  }
}

const readingStore = new ReadingStore();
export default readingStore;

import React from "react";

const CustomButton = ({ handleClick,label,classes,disabled }) => {
  return (
    <div>
      <button
        disabled={disabled}
        onClick={handleClick}
        className={`px-3 py-2 rounded-lg mt-2 ${disabled ? 'bg-gray-300 pointer-events-none' :  classes} }`}
      >
        {label}
      </button>
    </div>
  );
};

export default CustomButton;

import React from "react";
import { FaTrash } from 'react-icons/fa';

const TextCompletionOption = (props) => {
  const {
    blankIndex,
    blankOption,
    handleDeleteBlankOption,
    blanksData,
    setPayloadBlankOptions,
  } = props;

  const handleBlankOptionChange = (evt) => {
    const { value } = evt?.target;
    let newData = [...blanksData];
    newData[blankIndex]["blankName"] = value;
    setPayloadBlankOptions(newData);
  };

  const handleAddOption = (ind) => {
    let newData = [...blanksData];
    const newOption = {
      id: newData[ind]["answers"].length + 1,
      value: "",
      isActive: true,
      answerOption: `Option ${String.fromCharCode(
        newData[ind]["answers"].length + 65
      )}`,
      isCorrectAnswer: false,
      correctAnswer: "",
      answerPlaceHolder: "",
    };
    newData[ind]["answers"].push(newOption);
    setPayloadBlankOptions(newData);
  };

  const handleOptionChange = (evt,optionIndex,blankOptionIndex) => {
    let { value } = evt.target;
    let newData = [...blanksData];
    newData[blankOptionIndex]["answers"][optionIndex]["answerPlaceHolder"] = value;
    setPayloadBlankOptions(newData);
  }

  const handleOptionRadioChange = (evt,optionId,blankOptionIndex) => {
    console.log(optionId,blankOptionIndex,'index');
    let newData = [...blanksData];
    let options =  newData[blankOptionIndex]["answers"];
    let updatedOptions = options.map((option) =>
        option.id === optionId
          ? { ...option, isCorrectAnswer: evt.target.checked }
          : { ...option, isCorrectAnswer: false })
    newData[blankOptionIndex]["answers"] = updatedOptions;
    console.log(newData);
    setPayloadBlankOptions(newData);
  }

  const handleDeleteOption = (optionIndex,blankOptionIndex) => {
    let newData = [...blanksData];
    let options =  newData[blankOptionIndex]["answers"];
    options.splice(optionIndex,1);
    options = options.map((option,index) => ({ ...option, id:index+1 ,answerOption : `Option ${String.fromCharCode(index + 65)}`}))
    newData[blankOptionIndex]["answers"] = options;
    setPayloadBlankOptions(newData);
  }

  return (
    <>
      <div className="flex items-center w-full">
        <p>BlankName {blankOption?.id} : </p>
        <input
          type="text"
          name="blankOptionText"
          placeholder="Blank Option Text"
          value={blankOption?.blankName}
          onChange={(e) => handleBlankOptionChange(e)}
          className="py-3 w-[52%] px-4 mx-2 border bg-white border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
        />
        <button
          className="border border-[#ffa500] text-[#ffa500] rounded-full my-2 px-4 py-1 ml-4"
          onClick={() => handleAddOption(blankIndex)}
        >
          Add option
        </button>
        <FaTrash
        onClick={() => handleDeleteBlankOption(blankIndex)}
        className="text-red-600 cursor-pointer ml-4"
      />
      </div>
      <div className="ml-24">
        {
            blankOption?.answers.length > 0 && 
            blankOption?.answers.map((option,index) => (
                 <OptionsForBlanks
                    key={option?.id}
                    optionIndex={index}
                    optionData={option}
                    handleOptionChange={handleOptionChange}
                    handleOptionRadioChange={handleOptionRadioChange}
                    handleDeleteOption={handleDeleteOption}
                    blankOptionIndex={blankIndex}
                 />
            ))
        }
      </div>
    </>
  );
};

export default TextCompletionOption;

export const OptionsForBlanks = (props) => {
    const { optionIndex, optionData, handleOptionChange, handleOptionRadioChange, handleDeleteOption, blankOptionIndex   } = props;
  return (
    <div className="flex items-center gap-4 mt-4">
      <label className="text-[#4E5664] ">{optionData.answerOption}</label>{" "}
      <input
        type="text"
        name="optionText"
        placeholder="Option Text"
        value={optionData?.answerPlaceHolder}
        onChange={(e) => handleOptionChange(e,optionIndex,blankOptionIndex)}
        className="py-3 w-2/4 px-4  border bg-white border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
      />
      <div>
        <input
            type="radio"
            id={`correct-answer-${blankOptionIndex}-${optionIndex}`}
            name={`radio-${blankOptionIndex}-${optionIndex}`}
            className="ml-3"
            checked={optionData?.isCorrectAnswer}
            onChange={(e) => handleOptionRadioChange(e,optionData?.id,blankOptionIndex)}
        />
        <label htmlFor={`correct-answer-${blankOptionIndex}-${optionIndex}`} className="ml-2">Correct Answer</label>
      </div>
      <FaTrash
        onClick={() => handleDeleteOption(optionIndex,blankOptionIndex)}
        className="text-red-600 cursor-pointer ml-4"
      />
    </div>
  );
};

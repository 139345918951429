import React, { useEffect, useState } from "react";
import { IoIosArrowForward, IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { genericHeaders, serviceUrl } from "../../utils/apiConfig";
import writingStore from "../../Services/Stores/writingStore";
import axios from "axios";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";

const CreateWritingPart2 = observer(() => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const selectedSectionLocalStorage = JSON.parse(
    localStorage.getItem("selectedSectionDropDown")
  );
  const storedData = localStorage.getItem("updatedData");
  const updatedData = storedData ? JSON.parse(storedData) : {};
  let filedContainer = "flex w-full items-center";
  let labelStyle = "w-2/6 flex justify-start";
  let labelContentStyle =
    "border border-gray-400 rounded-md p-2 w-full justify-start outline-none bg-white";
  let essayTypeOptions = selectedSectionLocalStorage?.testType;

  const { partTwoPayload, questionsPartTwo} = writingStore;

  const partTwoValuesChange = async (e) => {
    let sectionValue = id
      ? updatedData.section
      : selectedSectionLocalStorage.description;
    const { name, value } = e.target;
    writingStore.setPartTwoPayload({
      ...partTwoPayload,
      [name]: value,
      section: sectionValue,
    });
  };

  useEffect(() => {
    if (id) {
      writingStore.setUpdatedData(
        questionsPartTwo.find((item) => item?.id === id)
      );
      let removeActive = JSON.parse(JSON.stringify(updatedData));
      writingStore.setPartTwoPayload(removeActive);
    }
  }, []);
  const submitData = async () => {
    if (id) {
      writingStore.setPartTwoPayload({
        ...partTwoPayload,
        createdDate: updatedData.createdDate,
        id: updatedData.id,
      });
    }
    try {
      setLoading(true);
      const url = `${
        id
          ? `${serviceUrl}/api/writing/questions/update?questionId=${id}`
          : `${serviceUrl}/api/writing/questions/add`
      }`;
      const response = await axios[id ? "put" : "post"](url, partTwoPayload, {
        headers: genericHeaders(),
      });
      if (response.status === 200) {
        setLoading(false);
        toast.success("Saved Successfully");
        navigate("/writing");
        writingStore.setpartTwoPayload({});
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="mx-auto w-[80%] min-h-screen">
      <div className="mt-20">
        <div>
          <button
            className="hover:bg-slate-500 hover:text-white rounded-md flex items-center bg-gray-300 py-2 px-6 mb-4"
            onClick={() => navigate("/writing")}
          >
            <IoMdArrowBack /> <span className="ml-1">Back</span>
          </button>
        </div>
        <div className="flex items-center">
          Writing <IoIosArrowForward /> Section-2 <IoIosArrowForward />{" "}
          {id ? "Edit Task" : "Create Task"}
        </div>
        <div className="flex flex-col mt-4 gap-6">
          <div className={filedContainer}>
            <label className={labelStyle}>Name<span className="text-red-600 text-xl">*</span></label>
            <input
              type="text"
              className={labelContentStyle}
              placeholder="Name"
              name="name"
              onChange={partTwoValuesChange}
              value={partTwoPayload?.name}
            />
          </div>

          <div className={filedContainer}>
            <label className={labelStyle}>Task Type<span className="text-red-600 text-xl">*</span></label>
            <select
              className={labelContentStyle}
              placeholder="Task Type"
              name="taskType"
              onChange={partTwoValuesChange}
              value={partTwoPayload?.taskType}
            >
              <option value={""}>Select</option>
              {essayTypeOptions.map((item, index) => (
                <option key={index}>{item}</option>
              ))}
            </select>
          </div>
          <div className={filedContainer}>
            <label className={labelStyle}>Task<span className="text-red-600 text-xl">*</span></label>
            <textarea
              className={labelContentStyle}
              placeholder="Task"
              name="task"
              rows={7}
              columns={10}
              onChange={partTwoValuesChange}
              value={partTwoPayload?.task}
            />
          </div>
        </div>

        <button
          className={`${
            partTwoPayload?.taskType === "" ||
            partTwoPayload?.taskType === "Select" ||
            partTwoPayload.task === "" ||
            partTwoPayload.name === ""
              ? "bg-gray-400"
              : "bg-[#E31837]"
          } text-white rounded-md py-2 px-6 mb-4`}
          onClick={submitData}
          disabled={
            partTwoPayload?.taskType === "" ||
            partTwoPayload?.taskType === "Select" ||
            partTwoPayload.task === "" ||
            partTwoPayload.name === ""
          }
        >
           {loading ? (
            <svg
              className="animate-spin h-5 w-5 text-white mr-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
          ) : (
          <span className="ml-1">{!id ? "Save" : "Update"}</span>
        )}
        </button>
      </div>
    </div>
  );
});

export default CreateWritingPart2;

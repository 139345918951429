import { observer } from 'mobx-react-lite'
import React from 'react'
import { IoIosArrowForward, IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const ShortForm = observer(() => {
    const navigate = useNavigate();
   
  return (
    <div>
    <button
      className="hover:bg-slate-500 hover:text-white rounded-md flex items-center bg-gray-300 py-2 px-6 mb-4"
      onClick={() => navigate("/satMaths")}
    >
      <IoMdArrowBack /> <span className="ml-1">Back</span>
      <div></div>
    </button>
  </div>
  )
})

export default ShortForm
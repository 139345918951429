import React from "react";
import { Tooltip } from "react-tooltip";

const CustomToolTip = ({ id, place, content, tooltipMaxWidth }) => {
  return (
    <Tooltip
      style={{
        color: "black",
        background: "#FFEAEA",
        borderRadius: "5px",
        maxWidth: tooltipMaxWidth ? tooltipMaxWidth : "200px",
        whiteSpace: "normal",
      }}
      id={id}
      place={place}
      content={content}
    />
  );
};

export default CustomToolTip;

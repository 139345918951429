import React from "react";
import { FaTrash } from "react-icons/fa";

const OptionComponent = ({
  questionIndex,
  optionIndex,
  questionType,
  option,
  handleFormDataChange,
  alphabeticalCount,
  handleDeleteOption,
  qId,
}) => {
  return (
    <div key={optionIndex}>
      <label className="mr-4 mb-1 text-[#4E5664] flex items-center">
        Option {alphabeticalCount}{" "}
        <FaTrash
          onClick={() => handleDeleteOption(questionIndex, qId, optionIndex)}
          className="text-[red] ml-2 cursor-pointer"
        />
      </label>
      <div className="flex items-center my-2 w-full">
        <input
          className="flex-1 bg-white py-3 px-4 mr-10 border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
          type="text"
          placeholder="Option"
          value={option.answerOption}
          onChange={(e) =>
            handleFormDataChange(
              e,
              questionIndex,
              optionIndex,
              questionType,
              "answerOption"
            )
          }
        />
        <div className="flex items-center justify-end">
          <input
            className="mr-2 bg-white"
            type="radio"
            name={`option_${questionIndex}`}
            checked={option.isCorrectAnswer}
            onChange={(e) =>
              handleFormDataChange(
                e,
                questionIndex,
                optionIndex,
                questionType,
                "isCorrectAnswer"
              )
            }
          />
          <label>Correct Answer</label>
        </div>
      </div>
    </div>
  );
};

export default OptionComponent;

import React, { useEffect, useState } from "react";
import { IoIosArrowForward, IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { genericHeaders, serviceUrl } from "../../utils/apiConfig";
import writingStore from "../../Services/Stores/writingStore";
import axios from "axios";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";
import analyticalWritingStore from "../../Services/Stores/analyticalWritingStore";



const AnalyticalWritingQuestions = observer(() => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  let analyticalSelectedValue = localStorage.getItem("analyticalSelectedValue")
const {payload,selectedQuestionType,selectedSectionId,updatedDataGre,selectedSectionName,selectedQuestionId} = analyticalWritingStore;
let moduleId = localStorage.getItem("moduleId");

  const storedData = localStorage.getItem("updatedData");
  const updatedData = storedData ? JSON.parse(storedData) : {};
  let filedContainer = "flex w-full items-center";
  let labelStyle = "w-2/6 flex justify-start";
  let labelContentStyle =
    "border border-gray-400 rounded-md p-2 w-full justify-start outline-none bg-white";
    let selectedQuestionTypeName = localStorage.getItem(
      "selectedQuestionTypeName"
    );
  
    const getQuestionsById = async () => {
      try {
        const url = `${serviceUrl}/api/gre/questions/getbyid?questionId=${selectedQuestionId||id}`;
        const res = await axios.get(url, {
          headers: genericHeaders(),
        });
        const quesType =  res.data.data.questionTypeId === selectedQuestionType.id;
        analyticalWritingStore.setSelectedQuestionType({
          id: quesType._id,
          name: quesType.name,
        });
        analyticalWritingStore.setPayload(res.data.data);
      } catch (error) {
        console.log(error, "error");
      }
    };
  
    useEffect(() => {
      if (selectedQuestionId || id) {
        getQuestionsById();
      }
    }, [selectedQuestionId,id]);
  const analyticalValuesChange = async (e) => {

    const{name,value} = e.target;
    if (e.target.name === "title") {
        analyticalWritingStore.setPayload((prevQuestion) => ({
          ...prevQuestion,
          title: e.target.value,
        }));
      }
      if (e.target.name === "passage") {
        analyticalWritingStore.setPayload((prevQuestion) => ({
          ...prevQuestion,
          passage: e.target.value,
        }));
      }
      if (e.target.name === "explanation") {
        analyticalWritingStore.setPayload((prevQuestion) => ({
          ...prevQuestion,
          explanation: e.target.value,
        }));
      }
      
    analyticalWritingStore.setPayload({...payload,[name]:value})
  };
  useEffect(() => {
    analyticalWritingStore.setPayload({...payload,sectionId: selectedSectionId,questionTypeId: selectedQuestionType.id|| selectedQuestionTypeName?.id,moduleId: moduleId})
  },[])
  const submitData = async () => {

    
    try {
      setLoading(true);
      const url = `${
        id
          ? `${serviceUrl}/api/gre/questions/update?questionId=${selectedQuestionId||id}`
          : `${serviceUrl}/api/gre/questions/create`
      }`;
      const response = await axios[id ? "put" : "post"](url, payload, {
        headers: genericHeaders(),
      });
      if (response.status === 201 ||response.status === 200 ) {
        analyticalWritingStore.setSelectedQuestionType("");
        analyticalWritingStore.setCurrentsectionId("");
        setLoading(false);
        toast.success("Saved Successfully");
        navigate("/analyticalWriting");
        analyticalWritingStore.setSelectedSectionName("");

      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };


  return (
    <div className="mx-auto w-[80%] min-h-screen">
      <div className="mt-20">
        <div>
          <button
            className="hover:bg-slate-500 hover:text-white rounded-md flex items-center bg-gray-300 py-2 px-6 mb-4"
            onClick={() => navigate("/analyticalWriting")}
          >
            <IoMdArrowBack /> <span className="ml-1">Back</span>
          </button>
        </div>
        <div className="flex items-center">
          Analytical Writing <IoIosArrowForward /> {selectedSectionName || analyticalSelectedValue?.name}{" "}
          <span className="flex items-center">
            <IoIosArrowForward />{" "}
          </span>{" "}
          {selectedQuestionType.name || selectedQuestionTypeName?.name}
        </div>
        <div className="flex flex-col mt-4 gap-6">
          <div className={filedContainer}>
            <label className={labelStyle}>Title<span className="text-[#ffa500] text-xl">*</span></label>
            <textarea
              className={labelContentStyle}
              placeholder="Title"
              name="title"
              rows={2}
              columns={10}
              onChange={(e)=>{analyticalValuesChange(e)}}
              value={payload?.title}
            />
          </div>

          <div className={filedContainer}>
            <label className={labelStyle}>Passage<span className="text-[#ffa500] text-xl">*</span></label>
            <textarea
              className={labelContentStyle}
              placeholder="Passage"
              name="passage"
              rows={7}
              columns={10}
              onChange={(e)=>{analyticalValuesChange(e)}}
              value={payload?.passage}
            />
          </div>
          <div className={filedContainer}>
            <label className={labelStyle}>Explanation</label>
            <textarea
              className={labelContentStyle}
              placeholder="Explanation"
              name="explanation"
              rows={7}
              columns={10}
              onChange={(e)=>{analyticalValuesChange(e)}}
              value={payload?.explanation}
            />
          </div>
        </div>

        <button
          className={`${
            payload?.title === "" ||
            payload?.passage === "" 
              ? "bg-gray-400"
              : "bg-[#ffa500]"
          } text-white rounded-md py-2 px-6 mb-4`}
          onClick={submitData}
          disabled={
            payload?.title === "" ||
            payload?.passage === "" 
          }
        >
           {loading ? (
            <svg
              className="animate-spin h-5 w-5 text-white mr-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
          ) : (
          <span className="ml-1">{!id ? "Save" : "Update"}</span>
        )}
        </button>
      </div>
    </div>
  );
});

export default AnalyticalWritingQuestions;

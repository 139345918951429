import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { genericHeaders, serviceUrl } from "../utils/apiConfig";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";

const SignupPage = () => {
  const [singupData, setSingupData] = useState({});
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChangeLoginData = (e) => {
    const { value, name } = e.target;
    setSingupData({ ...singupData, [name]: value });
  };

  const signup = async () => {
    setLoading(true);
    try {
      const url = `${serviceUrl}/api/auth/signup`;
      const response = await axios.post(url, singupData, {
        headers: genericHeaders(),
      });
      if (response.status === 200) {
        setLoading(false);
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const disableSignupButton = () => {
    if (
      errorMessage ||
      loading ||
      !singupData.userName ||
      !singupData.password ||
      !confirmPassword
    ) {
      return true;
    } else return false;
  };

  return (
    <div
      className="body-bg min-h-screen pt-12 md:pt-20 pb-6 px-2 md:px-0"
      style={{ fontFamily: "Lato, sans-serif" }}
    >
      <header className="max-w-lg mx-auto">
        <h1 className="text-4xl font-bold text-black text-center">IELTS-CMS</h1>
      </header>

      <main className="bg-white max-w-lg mx-auto p-8 md:p-12 my-10 rounded-lg shadow-2xl">
        <section>
          <h3 className="font-bold text-lg">Welcome to IELTS-CMS</h3>
          <p className="text-gray-600 pt-2">Create your account.</p>
        </section>

        <section className="mt-10">
          <div className="flex flex-col">
            <div className="mb-6 pt-3 rounded bg-gray-200">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 ml-3"
                htmlFor="email"
              >
                Email
              </label>
              <input
                value={singupData.userName}
                name="userName"
                onChange={handleChangeLoginData}
                type="text"
                id="email"
                className="bg-gray-200 rounded w-full text-gray-700 focus:outline-none border-b-4 border-gray-300 focus:border-purple-600 transition duration-500 px-3 pb-3"
              />
            </div>
            <div className="mb-6 pt-3 rounded bg-gray-200 relative">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 ml-3"
                htmlFor="password"
              >
                Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={handleChangeLoginData}
                id="password"
                value={singupData.password}
                className="bg-gray-200 rounded w-full text-gray-700 focus:outline-none border-b-4 border-gray-300 focus:border-purple-600 transition duration-500 px-3 pb-3 pr-10" // Adjusted the paddingRight for the icon
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                {showPassword ? (
                  <IoIosEye
                    className="text-gray-500 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <IoIosEyeOff
                    className="text-gray-500 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>
            </div>
            <div className="mb-6 pt-3 rounded bg-gray-200 relative">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 ml-3"
                htmlFor="password"
              >
                Confirm Password
              </label>
              <input
                value={confirmPassword}
                onBlur={() => {
                  if (singupData.password !== confirmPassword) {
                    setErrorMessage(
                      "Password and Confirm Password does not match"
                    );
                  } else {
                    setErrorMessage("");
                  }
                }}
                name="confirmPassword"
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                className="bg-gray-200 rounded w-full text-gray-700 focus:outline-none border-b-4 border-gray-300 focus:border-purple-600 transition duration-500 px-3 pb-3 pr-10" // Adjusted the paddingRight for the icon
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                {showConfirmPassword ? (
                  <IoIosEye
                    className="text-gray-500 cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                ) : (
                  <IoIosEyeOff
                    className="text-gray-500 cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                )}
              </div>
            </div>
            <p className="text-[red] mb-3">{errorMessage}</p>
            <button
              disabled={disableSignupButton()}
              onClick={signup}
              className={`${
                disableSignupButton() && "opacity-50 cursor-not-allowed"
              } bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200`}
            >
              {loading ? "Signing Up..." : "Sign Up"}
            </button>

            <div className="max-w-lg mx-auto text-center mt-12 mb-4">
              <p className="text-black">
                Already have an account?{" "}
                <Link to="/" className="font-bold hover:underline">
                  Sign In
                </Link>
                .
              </p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default SignupPage;

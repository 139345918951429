import React from "react";
import { useNavigate } from "react-router-dom";
import { clearCahce } from "../../Services/token";
import readingStore from "../../Services/Stores/readingStore";
import listeningStore from "../../Services/Stores/listeningStore";
import { observer } from "mobx-react-lite";
import satReadingStore from "../../Services/Stores/satReadingStore";

const CustomHeader = observer(() => {
  const navigate = useNavigate();

  const handleLogout = () => {
    clearCahce();
    navigate("/");
    readingStore.setSelectedSectionId("");
    listeningStore.setSelectedSectionId("");
    satReadingStore.setSectionId("");
  };

  return (
    <div className="w-full flex justify-end fixed bg-white shadow-md">
      <button onClick={handleLogout} className="pr-10 py-4 text-black">
        Logout
      </button>
    </div>
  );
});

export default CustomHeader;

import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { observer } from "mobx-react-lite";
import { genericHeaders, serviceUrl } from "../../utils/apiConfig";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import CustomToolTip from "../Common/CustomToolTip";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import ErrorPage from "../../Components/Common/ErrorPage";
import { Dialog, Transition } from "@headlessui/react";
import verbalReasoningStore from "../../Services/Stores/verbalReasoningStore";
import GreQuestionTypeModal from "./greQuestionTypeModal";
import Pagination from "./Pagination";

const VerbalReasoning = observer(() => {
  const navigate = useNavigate();
  const {
    greSections,
    selectedSectionId,
    currentModuleQuestions,
    questionType,
    payload,
    currentClickedQuestion,
  } = verbalReasoningStore;
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState({
    statusCode: "",
    message: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = currentModuleQuestions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  let moduleId = localStorage.getItem("moduleId");
  const fetchSections = async () => {
    try {
      setLoading(true);
      const url = `${serviceUrl}/api/gre/section/getAll`;
      const response = await axios.get(url, { headers: genericHeaders() });
      verbalReasoningStore.setGreSections(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const getAllQuestions = async () => {
    try {
      const url = `${serviceUrl}/api/gre/questions/getAll`;
      const response = await axios.get(url, { headers: genericHeaders() });
      if (response.status === 200) {
        let moduleQuestions = response.data?.data;
        const filteredQuestions = moduleQuestions.filter(
          (item) =>
            item.moduleId === moduleId && item.sectionId === selectedSectionId
        );
        verbalReasoningStore.setUpdatedDataGre(filteredQuestions);
        const updatedQuestions = filteredQuestions.map((item, index) => ({
          ...item,
          questionNumber: index + 1,
        }));
        verbalReasoningStore.setCurrentModuleQuestions(updatedQuestions);
      }
      if (response.status === 401) {
        setError(true);
        setErrorData({
          statusCode: response.status,
          errorMessage: "Authentication Required",
        });
      }
    } catch (e) {
      console.log(e);
      setError(true);
    }
  };
  const fetchQuestionTypes = async () => {
    if (selectedSectionId) {
      const url = `${serviceUrl}/api/gre/questiontype/getAll`;
      setLoading(true);
      try {
        const response = await axios.get(url, { headers: genericHeaders() });
        if (response.status === 200) {
          let questionTypeId = response?.data
            ?.filter((item) => item.moudleId === moduleId)
            .map((item) => item);
          localStorage.setItem(
            "allQuestionTypes",
            JSON.stringify(questionTypeId)
          );
          verbalReasoningStore.setQuestionType(questionTypeId);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.log(e);
        setError(true);
        setErrorData({
          statusCode: e.response?.data?.status,
          errorMessage: e.response?.data?.message,
        });
      }
    }
  };

  useEffect(() => {
    getAllQuestions();
    fetchQuestionTypes();
  }, [selectedSectionId]);

  useEffect(() => {
    fetchSections();
  }, []);

  const handleQuestionTypeChange = (e) => {
    closeModal();
    navigate("/verbalReasoningQuestions");
  };

  const deleteQuestion = async () => {
    setLoading(true);
    try {
      const url = `${serviceUrl}/api/gre/questions/delete?questionId=${currentClickedQuestion?.["_id"]}`;
      const response = await axios.delete(url, { headers: genericHeaders() });
      if (response.status === 200) {
        let tempQuestions = currentModuleQuestions.filter(
          (item) => item._id !== currentClickedQuestion?.["_id"]
        );
        setLoading(false);
        verbalReasoningStore.setCurrentModuleQuestions(tempQuestions);
        toast.success("Question deleted Successfully");

        if (moduleId) getAllQuestions();
      }
    } catch (e) {
      setLoading(false);
      console.log(e, "error");
    }
  };

  const greSectionsRender = (greSections) => {
    return (
      <select
        className="py-2 px-4 border bg-white border-gray-200 rounded-lg text-base focus:border-[#ffa500] focus:ring-[#ffa500] disabled:opacity-50 disabled:pointer-events-none"
        onChange={(e) => {
          const selectedValue = e.target.value;
          const selectedName = e.target.name;
          const selectedSection = greSections.find(
            (item) => item._id === selectedValue
          );
          if (selectedSection) {
            verbalReasoningStore.setSelectedSectionName(selectedSection.name);
            localStorage.setItem(
              "verbalSelectedSectionName",
              JSON.stringify(selectedSection)
            );
          }
          verbalReasoningStore.setSelectedSectionId(selectedValue);
          verbalReasoningStore.savePersistedData();
        }}
        value={selectedSectionId || ""}
      >
        <option value="">Select</option>
        {greSections &&
          greSections.length > 0 &&
          greSections
            .filter((item) => item.moduleId === moduleId)
            .map((item) => (
              <option key={item._id} value={item._id}>
                {item.name}
              </option>
            ))}
      </select>
    );
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const closeDialogue = () => {
    setIsOpen(false);
  };
  const createVerbalResoningQuestions = () => {
    setIsModalOpen(true);
    verbalReasoningStore.setResetPayload();
    verbalReasoningStore.setVerbalSelectedQuestionType("");
  };

  return (
    <div className="w-full mx-auto min-h-screen">
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <RotatingLines
            visible={true}
            strokeColor="orange"
            height="96"
            width="96"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <>
          <GreQuestionTypeModal
            isOpen={isModalOpen}
            onClose={closeModal}
            onQuestionTypeChange={handleQuestionTypeChange}
            data={questionType && questionType}
          />
          {error ? (
            <ErrorPage
              statusCode={errorData.statusCode}
              message={errorData.errorMessage}
            />
          ) : (
            <div className="w-full  mx-auto min-h-screen">
              <div className="px-10">
                <div className="flex items-center justify-between w-full mt-20">
                  <div className="flex items-center">
                    <label className="mr-2 text-base whitespace-nowrap">
                      Select Section:
                    </label>
                    {greSectionsRender(greSections)}
                  </div>
                  <div className="w-full flex justify-end">
                    <button
                      className={`text-white px-3 py-2 rounded-lg mt-2 ${
                        (moduleId === "" && selectedSectionId === "") ||
                        moduleId === "" ||
                        selectedSectionId === ""
                          ? "bg-gray-400"
                          : "bg-[#ffa500] cursor-pointer"
                      } `}
                      onClick={createVerbalResoningQuestions}
                      disabled={
                        (moduleId === "" && selectedSectionId === "") ||
                        moduleId === "" ||
                        selectedSectionId === ""
                      }
                    >
                      Create Question
                    </button>
                  </div>
                </div>
              </div>
              <div>
                {selectedSectionId === "" ? (
                  <div className="flex text-center justify-center text-xl">
                    Select a section to view the data
                  </div>
                ) : (
                  <div className="text-[#4E5664] m-4">
                    <table>
                      <thead>
                        <tr>
                          <td>S.No</td>
                          <td>Question</td>
                          <td>Passage</td>
                          <td>Explanation</td>
                          <td>Actions</td>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.length > 0 ? (
                          currentItems.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.questionNumber}</td>
                                <td>{item.question}</td>
                                <td>{item.passage}</td>
                                <td>{item.explanation}</td>
                                <td>
                                  <div className="flex justify-around">
                                    <button className="cursor-pointer ">
                                      <BiEdit
                                        className="text-xl"
                                        data-tooltip-id="edit-tooltip"
                                        onClick={() => {
                                          verbalReasoningStore.setSelectedQuestionId(
                                            item._id
                                          );
                                          navigate(
                                            `/verbalReasoningQuestions/${item._id}`
                                          );
                                        }}
                                      />
                                    </button>
                                    <RiDeleteBin5Line
                                      className="text-red-500 text-xl cursor-pointer"
                                      onClick={() => {
                                        verbalReasoningStore.setCurrentClickedQuestion(
                                          item
                                        );
                                        setIsOpen(true);
                                      }}
                                      data-tooltip-id="delete-tooltip"
                                    />
                                    <CustomToolTip
                                      content={"Delete"}
                                      id={"delete-tooltip"}
                                      place={"bottom"}
                                    />
                                    <CustomToolTip
                                      content={"Edit"}
                                      id={"edit-tooltip"}
                                      place={"bottom"}
                                    />
                                    <Transition
                                      appear
                                      show={isOpen}
                                      as={Fragment}
                                    >
                                      <Dialog
                                        as="div"
                                        className="relative z-10"
                                        onClose={closeDialogue}
                                      >
                                        <Transition.Child
                                          as={Fragment}
                                          enter="ease-out duration-300"
                                          enterFrom="opacity-0"
                                          enterTo="opacity-100"
                                          leave="ease-in duration-200"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <div className="fixed inset-0" />
                                        </Transition.Child>

                                        <div className="fixed inset-0 overflow-y-auto">
                                          <div className="flex min-h-full items-center justify-center p-4 text-center">
                                            <Transition.Child
                                              as={Fragment}
                                              enter="ease-out duration-300"
                                              enterFrom="opacity-0 scale-95"
                                              enterTo="opacity-100 scale-100"
                                              leave="ease-in duration-200"
                                              leaveFrom="opacity-100 scale-100"
                                              leaveTo="opacity-0 scale-95"
                                            >
                                              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl border  bg-white p-6 text-left align-middle shadow-md transition-all">
                                                <Dialog.Title
                                                  as="h3"
                                                  className="text-lg font-medium leading-6 text-gray-900"
                                                >
                                                  Delete
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                  <p className="text-sm text-gray-500">
                                                    Are you sure you want to
                                                    delete the data?
                                                  </p>
                                                </div>

                                                <div className="mt-4">
                                                  <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-[#ffa500] px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                                                    onClick={() => {
                                                      verbalReasoningStore.setCurrentClickedQuestion(
                                                        {}
                                                      );
                                                      closeDialogue();
                                                    }}
                                                  >
                                                    Cancel
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 ml-6"
                                                    onClick={() => {
                                                      deleteQuestion();
                                                      closeDialogue();
                                                    }}
                                                  >
                                                    Delete
                                                  </button>
                                                </div>
                                              </Dialog.Panel>
                                            </Transition.Child>
                                          </div>
                                        </div>
                                      </Dialog>
                                    </Transition>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <div>NO QUESTIONS AVAILABLE</div>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                <Pagination
                  totalItems={currentModuleQuestions.length}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
});

export default VerbalReasoning;

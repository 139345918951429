import React, { useEffect, useState, useRef } from "react";
import { FaEye, FaTrash } from "react-icons/fa";
import { GetFile, upload_file_to_s3 } from "../Common/FileUpload";
import listeningStore from "../../Services/Stores/listeningStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { toast } from "react-toastify";

const FileUpload = observer((props) => {
  const [filePreview, setFilePreview] = useState(null);
  const [imageurl,setImageurl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [fileName, setFileName] = useState(""); // New state for file name
  const fileInputRef = useRef(null);
  const { formData, editMode } = listeningStore;

  let public_url;

  useEffect(() => {
    if (editMode) {
      setFilePreview(props.url);
      setFileName(listeningStore.uploadURL.fileName);
    }
  }, []);
  useEffect(() => {
    const getFileUrl = async () => {
      if(filePreview?.resourceName && filePreview?.resourceName?.trim() !== ""){
        setImageurl(filePreview?.resourceName);
      }else{
        let file_Url = filePreview?.fileName && await GetFile(filePreview.fileName);
        setImageurl(file_Url);
      }
    }
   getFileUrl()
  },[filePreview])


  const handleDocsChange = async (evt) => {
    const file_to_upload = evt.target.files[0];
    const maxSizeInBytes = 10 * 1024 * 1024; // max 10mb

      if (file_to_upload?.size > maxSizeInBytes) {
        toast.error('File size is limited to 10mb');
        return
      }
    try {
      const s3Data = await upload_file_to_s3(file_to_upload);
      public_url = s3Data?.data;
      setFilePreview(public_url);
      const transformedFiles = {
        fileName: file_to_upload.name,
        name: file_to_upload.name,
        resourceName: '',
        uploadDate: new Date().toISOString(),
        comment: "",
        extension: file_to_upload.name.split(".").pop(),
        s3Document: public_url
      };

      listeningStore.setUploadURL(transformedFiles);
      listeningStore.setButtonClick(true);
      const res = { ...formData };
      res.questions[props.qIndex]["imageUrl"] = transformedFiles;
      listeningStore.setFormData(res);
      if (Object.keys(public_url)  > 0) {
        listeningStore.setButtonDisable(false);
      }

      // Set the file name
      setFileName(file_to_upload.name);
    } catch (err) {
      console.error(err);
    }
  };

  const handleViewClick = () => {
    setIsModalOpen(true);
  };

  const handleDeleteClick = () => {
    listeningStore.setUploadURL("");
    setFilePreview(null);
    setIsModalOpen(false);
    setFileInputKey(Date.now());
    setFileName(""); // Clear the file name
    // Clear the input value
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };


  const handleClose = () => {
    listeningStore.setButtonClick(true);
    setIsModalOpen(false);
  };

  return (
    <div className="mt-[5rem] ml-10">
      <label className="upload-label">
        <input
          key={fileInputKey}
          type="file"
          onChange={handleDocsChange}
          ref={fileInputRef}
          style={{ display: "none" }}
          className="bg-white"
        />
        <button
          className="bg-green-500 text-white py-2 px-4 rounded cursor-pointer hover:bg-green-600"
          onClick={() => fileInputRef.current.click()}
        >
          Choose File
        </button>
      </label>

      {filePreview && (
        <div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              marginTop: "-43px",
              marginLeft: "180px",
            }}
          >
            <div className="font-bold">
              {editMode
                ? formData.questions[props.qIndex]?.imageUrl?.fileName
                : fileName}
            </div>
            <button
              className="inline-flex items-center bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-600"
              onClick={handleViewClick}
            >
              <FaEye className="mr-2" /> View
            </button>

            <button
              className=" inline-flex items-center bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              onClick={handleDeleteClick}
            >
              <FaTrash className="mr-2" /> Delete
            </button>
          </div>
          <div
            style={{
              overflow: "auto",
              minWidth: "100%",
              height: "auto",
              width: "auto",
            }}
          >
            <img
              src={imageurl}
              alt="Uploaded Preview"
              style={{
                display: isModalOpen ? "block" : "none",
                marginTop: "20px",
                width: "60%",
                height: "auto",
                marginLeft: "20px",
              }}
            />
          </div>

          {isModalOpen && (
            <div>
              <button
                onClick={handleClose}
                className="bg-blue-500 text-white px-4 py-2 rounded focus:outline-none hover:bg-blue-700 mt-[1.0rem] ml-[20px]"
              >
                Close
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default FileUpload;

import React, { useEffect, useState,Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import QuestionTypeModal from "../Components/Reading/QuestionTypeModal";
import satReadingStore from "../Services/Stores/satReadingStore";
import { serviceUrl, genericHeaders } from "../utils/apiConfig";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { FaTrash } from "react-icons/fa";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { RiDeleteBin5Line } from "react-icons/ri";
import { GrView } from "react-icons/gr";
import ErrorPage from "../Components/Common/ErrorPage";
import CustomToolTip from "../Components/Common/CustomToolTip";
import { Dialog, Transition } from "@headlessui/react";
import { BiEdit } from "react-icons/bi";

const SatReading =  observer(() => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(false);
  // const [currentClickedQuestion,setCurrentClickedQuestion] = useState({})
  const [errorData, setErrorData] = useState({
    statusCode: "",
    errorMessage: "",
  });
  const [isOpen,setIsOpen] = useState(false)

  const navigate = useNavigate();
  let location = useLocation();
  const {
    loading,
    setQuestionType,
    setIsLoading,
    setCurrentClickedQuestion,
    currentClickedQuestion,
    setModules,
    currentModuleId,
    currentModuleName,
    modules,
    setSectionId,
    setModuleId,
    setModuleName,
    setSectionsData,
    sectionsData,
    setSectionName,
    setQuestionTypes,
    questionCategory,
    setCurrentModule,
    currentModuleQuestions,
    currentSectionName,
    currentSectionId,
    savePersistedData,
    currentPage,
    setCurrentPage,
    setIsGetAllQuestionsLoaded,
    isGetAllQuestionsLoaded
  } = satReadingStore;
 
  const [recordsPerPage, setRecordsPerPage] = useState(50);


  const [currentRecords, setCurrentRecords] = useState([]);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  useEffect(() => {
    setCurrentRecords(currentModuleQuestions.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [currentModuleQuestions, currentPage]);

  const closeModal = () => {
    setIsModalOpen(false);
  };
const closeDialogue = () => {
  setIsOpen(false)
}

  const fetchModules = async (sections) => {
    let modules = {};
    const url = `${serviceUrl}/api/sat/module/getAll`;
    setIsLoading(true)
    try {
      const response = await axios.get(url, { headers: genericHeaders() });
      if (response.status === 200) {
        sections?.length > 0 &&
          sections.forEach((element) => {
            let module =
              response.data?.length > 0 &&
              response.data.filter((item) => item.sectionId === element._id);
            modules[`${element["name"]}`] = module;
          });
      }
      setModules(modules);
      setIsLoading(false);
    } catch (e) {
      console.log(e, "get modules SAT ");
      setIsLoading(false);
    }
  };
  const fetchSections = async () => {
    const url = `${serviceUrl}/api/sat/section/getAll`;
    setIsLoading(true);
    try {
      const response = await axios.get(url, { headers: genericHeaders() });
      if (response.status === 200) {
        fetchModules(response.data);
        setSectionsData(response.data);
        setIsLoading(false)
        if (response.status === 401) {
          setIsLoading(false)
          setError(true);
          setErrorData({
            statusCode: response.status,
            errorMessage: "Authentication Required",
          });
        }
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e, "get modules SAT ");
      setError(true);
      setErrorData({
        statusCode: e.response.data.status,
        errorMessage: e.response.data.message,
      });
    }
  };

  const createReadingQuestions = () => {
    setIsModalOpen(true);
    //
  };
  const handleQuestionTypeChange = (e) => {
    // if (questionType) {
    //   const updatedQuestions = [...formData.questions];
    //   const newQuestion = createNewQuestion(questionType);
    //   updatedQuestions.push(newQuestion);
    //   readingStore.setFormData({
    //     ...formData,
    //     questions: updatedQuestions,
    //   });
    //   closeModal();
    // }
    closeModal();
    navigate("/satReadingQuestions");
  };
  const fetchQuestionsCategory = async () => {
    const url = `${serviceUrl}/api/sat/questioncategory/getAll`;
    setIsLoading(true);
    try {
      const response = await axios.get(url, { headers: genericHeaders() });
      if (response.status === 200) {
        setQuestionTypes(response.data);
        setIsLoading(false)
      }
      if (response.status === 401) {
        setIsLoading(false)
        setError(true);
        setErrorData({
          statusCode: response.status,
          errorMessage: "Authentication Required",
        });
      }
    } catch (e) {
      setIsLoading(false)
      console.log(e);
      setError(true);
      setErrorData({
        statusCode: e.response.data.status,
        errorMessage: e.response.data.message,
      });
    }
  };
  const getAllQuestions = async () => {
    setIsGetAllQuestionsLoaded(true);

    try {
      const url = `${serviceUrl}/api/sat/questions/getAll`;
      const response = await axios.get(url, { headers: genericHeaders() });
      if (response.status === 200) {
        let moduleQuestions =
          response.data?.length > 0 &&
          response.data.filter(
            (item) =>
              item.moduleId === currentModuleId &&
              item.isActive &&
              item.sectionId === currentSectionId
          );
        setCurrentModule(moduleQuestions.map((item,index) =>({...item,questionNumber:index+1})));
        setIsGetAllQuestionsLoaded(false);
      }
      if (response.status === 401) {
        setIsGetAllQuestionsLoaded(false)
        setError(true);
        setErrorData({
          statusCode: response.status,
          errorMessage: "Authentication Required",
        });
      }
    } catch (e) {
      setIsGetAllQuestionsLoaded(false)
      console.log(e);
      setError(true);
      setErrorData({
        statusCode: e.response.data.status,
        errorMessage: e.response.data.message,
      });
    }
  };

  const deleteQuestion = async () => {
    setIsLoading(true)
    try {
      const url = `${serviceUrl}/api/sat/questions/delete?questionId=${currentClickedQuestion?.["_id"]}`;
      const response = await axios.delete(url, { headers: genericHeaders() });
      if (response.status === 204) {
        let tempQuestions = currentModuleQuestions.filter(
          (item) => item._id !== currentClickedQuestion?.["_id"]
        );
        setIsLoading(false)
        setCurrentModule(tempQuestions);
        toast.success("Question deleted Successfully");
        setCurrentClickedQuestion({})
        if (currentModuleId) getAllQuestions();
      }
    } catch (e) {
      setIsLoading(false)
      console.log(e, "error");
    }
  };
  // const questionCategory = [
  //   {
  //     _id: "25",
  //     isActive: true,
  //     name: "Multiple Choice",
  //     description: "Multiple Choice",
  //     feildsIncluded: ["Paragraph", "Table", "Multiple_Answers", "Explanation"],
  //   },
  //   {
  //     _id: "25",
  //     isActive: true,
  //     name: "Short Form",
  //     description: "Short Form",
  //     feildsIncluded: ["Paragraph", "Table", "Multiple_Answers", "Explanation"],
  //   },
  // ];

  const viewQuestion = (id, qns) => {
    navigate(`/viewQuestion/${id}`);
  };
  useEffect(() => {
    fetchSections();
    fetchQuestionsCategory();
  }, []);
  useEffect(() => {
    if (currentModuleId) getAllQuestions();
  }, [currentModuleId]);

  useEffect(() => {
    let { pathname } = location;
    // setModuleId("");
    if (pathname === "/satReading") {
      let obj =
        sectionsData &&
        sectionsData.length > 0 &&
        sectionsData.find((item) => item.name === "ReadingAndWriting");
      setSectionName("ReadingAndWriting");
      setSectionId(obj?._id);
    }
    if (pathname === "/satMaths") {
      let obj =
        sectionsData &&
        sectionsData.length > 0 &&
        sectionsData.find((item) => item.name === "Mathematics");
      setSectionName("Mathematics");
      setSectionId(obj?._id);
    }
  }, [location]);


  return (
    <>
      {loading || isGetAllQuestionsLoaded  ? (
        <div className="flex justify-center items-center min-h-screen">
          <RotatingLines
            visible={true}
            strokeColor="blue"
            height="96"
            width="96"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <>
          <QuestionTypeModal
            isOpen={isModalOpen}
            onClose={closeModal}
            onQuestionTypeChange={handleQuestionTypeChange}
            setQuestionType={setQuestionType}
            data={questionCategory}
          />
          {error ? (
            <ErrorPage
              statusCode={errorData.statusCode}
              message={errorData.errorMessage}
            />
          ) : (
            <div className="w-full  mx-auto min-h-screen">
              <div className="px-10">
                <div className="flex items-center justify-between w-full mt-20">
                  <div className="flex items-center">
                    <label className="mr-2 text-base whitespace-nowrap">
                      Select Section:
                    </label>
                    <select
                      className="py-2 px-4 bg-white border border-gray-200 rounded-lg text-base"
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setModuleId("");
                          setModuleName("");

                        } else {
                          let obj =
                            modules[`${currentSectionName}`]?.length > 0 &&
                            modules[`${currentSectionName}`].find(
                              (item) => item._id === e.target.value
                            );
                          let sections = sectionsData.find(
                            (item) => item._id === obj.sectionId
                          );
                          setSectionName(sections.name);
                          setModuleId(e.target.value);
                          setSectionId(obj.sectionId);
                          setModuleName(obj.name);
                          savePersistedData();
                        }
                        setCurrentPage(1)
                      }}
                      value={currentModuleId}
                    >
                      <option value="" name="">
                        Select
                      </option>
                      {modules[`${currentSectionName}`]?.length > 0 &&
                        modules[`${currentSectionName}`].map((item) => {
                          return (
                            <option
                              value={item._id}
                              key={item._id}
                              name={item.name}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="w-full flex justify-end">
                    <button
                      className={`text-white px-3 py-2 rounded-lg mt-2 ${
                        currentModuleId === "" || currentModuleName === ""
                          ? "bg-gray-400"
                          : "bg-blue-700 cursor-pointer"
                      } `}
                      onClick={createReadingQuestions}
                      disabled={
                        currentModuleId === "" || currentModuleName === ""
                      }
                    >
                      Create Question
                    </button>
                  </div>
                </div>
              </div>
              <div>
                {!currentModuleId ? (
                  <div className="flex text-center justify-center text-xl">
                    Select a section to view the data
                  </div>
                ) : (
                  <div className="text-[#4E5664] m-4">
                    <table>
                      <thead>
                        <tr>
                          <td>S.No</td>
                          <td>Question Name</td>
                          <td>Question Description</td>
                          <td>Actions</td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          currentRecords?.length > 0 ?
                          currentRecords.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.questionNumber}</td>
                                <td>{item.question}</td>
                                <td>{item.description}</td>
                                <td>
                                  <div className="flex justify-around">
                                    <RiDeleteBin5Line
                                      className="text-red-500 text-xl cursor-pointer"
                                      onClick={() => {
                                        setCurrentClickedQuestion(item);
                                        setIsOpen(true);
                                      }}
                                      data-tooltip-id="delete-tooltip"
                                    />
                                    <button className="cursor-pointer ">
                                      <BiEdit
                                        className="text-xl"
                                        data-tooltip-id="edit-tooltip"
                                        onClick={() => {
                                          let questionTypeMatched =
                                            questionCategory.find(
                                              (itm) =>
                                                itm._id === item.questionTypeId
                                            );
                                          setQuestionType(
                                            questionTypeMatched?.name
                                          );
                                          // let sections = sectionsData.find(
                                          //   (item) => item._id === obj.sectionId
                                          // );
                                          // setSectionName(sections.name);
                                          // setModuleId(item.moduleId);
                                          // setSectionId(item.sectionId);
                                          // setModuleName(obj.name);
                                          // savePersistedData();
                                          navigate(
                                            `/satReadingQuestions/${item._id}`
                                          );
                                        }}
                                      />
                                    </button>

                                    <CustomToolTip
                                      content={"Delete"}
                                      id={"delete-tooltip"}
                                      place={"bottom"}
                                    />
                                    <CustomToolTip
                                      content={"Edit"}
                                      id={"edit-tooltip"}
                                      place={"bottom"}
                                    />
                                    <Transition
                                      appear
                                      show={isOpen}
                                      as={Fragment}
                                    >
                                      <Dialog
                                        as="div"
                                        className="relative z-10"
                                        onClose={closeDialogue}
                                      >
                                        <Transition.Child
                                          as={Fragment}
                                          enter="ease-out duration-300"
                                          enterFrom="opacity-0"
                                          enterTo="opacity-100"
                                          leave="ease-in duration-200"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <div className="fixed inset-0" />
                                        </Transition.Child>

                                        <div className="fixed inset-0 overflow-y-auto">
                                          <div className="flex min-h-full items-center justify-center p-4 text-center">
                                            <Transition.Child
                                              as={Fragment}
                                              enter="ease-out duration-300"
                                              enterFrom="opacity-0 scale-95"
                                              enterTo="opacity-100 scale-100"
                                              leave="ease-in duration-200"
                                              leaveFrom="opacity-100 scale-100"
                                              leaveTo="opacity-0 scale-95"
                                            >
                                              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl border  bg-white p-6 text-left align-middle shadow-md transition-all">
                                                <Dialog.Title
                                                  as="h3"
                                                  className="text-lg font-medium leading-6 text-gray-900"
                                                >
                                                  Delete
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                  <p className="text-sm text-gray-500">
                                                    Are you sure you want to
                                                    delete the data?
                                                  </p>
                                                </div>

                                                <div className="mt-4">
                                                  <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                                                    onClick={() => {
                                                      setCurrentClickedQuestion(
                                                        {}
                                                      );
                                                      closeDialogue();
                                                    }}
                                                  >
                                                    Cancel
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 ml-6"
                                                    onClick={() => {
                                                      let questionTypeMatched =
                                                        questionCategory.find(
                                                          (itm) =>
                                                            itm._id ===
                                                            item.questionTypeId
                                                        );
                                                      deleteQuestion();
                                                      closeDialogue();
                                                    }}
                                                  >
                                                    Delete
                                                  </button>
                                                </div>
                                              </Dialog.Panel>
                                            </Transition.Child>
                                          </div>
                                        </div>
                                      </Dialog>
                                    </Transition>

                                    {/* {currentSectionName === "Mathematics" && (
                                <GrView
                                  className="text-blue-500 text-xl cursor-pointer"
                                  onClick={() =>
                                    viewQuestion(item._id, item.question)
                                  }
                                />
                              )} */}
                                  </div>
                                </td>
                              </tr>
                            );
                          }) :<div>NO QUESTIONS AVAILABLE</div>}
                      </tbody>
                    </table>
                    <div className="flex justify-between my-4 mx-2 items-center">
                      <button
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="px-4 py-2 text-white bg-blue-500 rounded-lg font-bold"
                      >
                        Prev
                      </button>
                      <p>
                        {currentPage} of {Math.ceil(currentModuleQuestions.length/50)}
                      </p>
                      <button
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={
                          currentPage ===
                          Math.ceil(
                            currentModuleQuestions.length / recordsPerPage
                          )
                        }
                        className="px-4 py-2 text-white bg-blue-500 rounded-lg font-bold"
                      >
                        Next
                      </button>
                      <div></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
});

export default SatReading;

import React from 'react';
import readingStore from '../../Services/Stores/readingStore';

const IdentifyingInformation = ({optionIndex,questionIndex,handleFormDataChange,option}) => {
  return (
    <div key={optionIndex}>
    <label>
      <input
        className="my-2 mr-2"
        type="radio"
        name={`option_${questionIndex}_trueFalse_${optionIndex}`}
        checked={option.isCorrectAnswer}
        onChange={(e) =>{
          handleFormDataChange(
            e,
            questionIndex,
            optionIndex,
            "Identifying Information (True/False/Not Given)",
            "isCorrectAnswer"
          );
          if(e.target.checked){
            readingStore.setMultipleSingle(true);
          }
          else{
            readingStore.setMultipleSingle(false);
          }
        }
        }
      />
      {option.answerOption}
    </label>
  </div>
  )
}

export default IdentifyingInformation

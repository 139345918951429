import React, { useEffect, useState } from "react";
import CustomButton from "../Components/Common/CustomButton";
import { RotatingLines } from "react-loader-spinner";
import ErrorPage from "../Components/Common/ErrorPage";
import TaskOne from "../Components/Writing/TaskOne";
import TaskTwo from "../Components/Writing/TaskTwo";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { genericHeaders, serviceUrl } from "../utils/apiConfig";
import { observer } from "mobx-react-lite";
import writingStore from "../Services/Stores/writingStore";

const WritingMain = observer(() => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { sections, selectedSectionId, selectedSection } = writingStore;
  const selectedSectionLocalStorage = localStorage.getItem(
    "selectedSectionDropDown"
  );
  let parsedSelectedSection = null;

  if (selectedSectionLocalStorage) {
    try {
      parsedSelectedSection = JSON.parse(selectedSectionLocalStorage);
    } catch (error) {
      console.error("Failed to parse JSON:", error);
    }
  }
  const fetchSections = async () => {
    try {
      const url = `${serviceUrl}/api/writing/section/getall`;
      const response = await axios.get(url, { headers: genericHeaders() });
      if (response.status === 200) {
        let formattedData = JSON.parse(JSON.stringify(response.data));
        writingStore.setSections(formattedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchSections();
    if (parsedSelectedSection?.id) {
      writingStore.setSelectedSectionId(parsedSelectedSection.id);
    } else {
      writingStore.setSelectedSectionId("");
    }
  }, []);

  const sectionChange = (e) => {
    let matchedSection = sections.find((item) => item._id === e.target.value);
    matchedSection && writingStore.setSelectedSectionId(matchedSection?._id);
    writingStore.setSelectedSection(matchedSection);
    writingStore.savePersistedData();
  };
  return (
    <div className="w-[80%] mx-auto min-h-screen">
      {isLoading ? (
        <div className="flex justify-center items-center min-h-screen">
          <RotatingLines
            visible={true}
            strokeColor="red"
            height="96"
            width="96"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : error ? (
        <ErrorPage />
      ) : (
        <div>
              <div className="mt-20 flex justify-between">
                <div className="">
                  <label className="mr-2 text-base whitespace-nowrap">
                    Select Section:
                  </label>
                  <select
                    className="py-2 px-4 border bg-white border-gray-200 rounded-lg text-base focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    onChange={(e) => {
                      sectionChange(e);
                    }}
                    value={selectedSectionId}
                    disabled={sections.length === 0}
                  >
                    <option  disabled value={""}>
                      {parsedSelectedSection?.id
                        ? parsedSelectedSection?.name
                        : "Select"}
                    </option>
                    {sections &&
                      sections.length > 0 &&
                      sections.map((item) => (
                        <option
                          key={item._id}
                          value={item._id}
                          name={item.name.toUpperCase()}
                        >
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div>
                  <CustomButton
                    disabled={!selectedSectionId || selectedSectionId === ""}
                    classes={"bg-[#E31837] text-white"}
                    label="Add Task"
                    handleClick={() => {
                      writingStore.setPartOnePayload({});
                      if (selectedSection?.description === "Part-1") {
                        navigate("/createWritingPart1");
                      } else {
                        navigate("/createWritingPart2");
                      }
                    }}
                  />
                </div>
              </div>
              {selectedSectionId && selectedSectionId !== "" ? (
              
                <>
                  {selectedSection.description === "Part-1" &&
                  selectedSectionId ? (
                    <TaskOne />
                  ) : (
                    selectedSection.description === "Part-2" &&
                    selectedSectionId && <TaskTwo />
                  )}
                </>
          ) : (
            <div className="text-xl font-medium justify-center mt-14 flex">
              Select a Section to View Data
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default WritingMain;

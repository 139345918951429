import React from 'react';
import satReadingStore from '../Services/Stores/satReadingStore';
import { observer } from 'mobx-react-lite';
import MultipleChoiceQuestion from '../Components/Sat/multipleChoiceQuestion';
import ShortForm from '../Components/Sat/shortForm';

const ReadingQuestionsSAT =observer(() => {
    const {questionType} =satReadingStore;

   
  return (
    <div className="flex flex-col h-full mx-auto w-[80%] mt-10 p-8">
        {/* {questionType ==='Multiple choice-Single Answer' ? <MultipleChoiceQuestion /> : <ShortForm />} */}
        <MultipleChoiceQuestion />
    </div>
  )
})

export default ReadingQuestionsSAT
import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowForward, IoMdArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { genericHeaders, serviceUrl } from "../../utils/apiConfig";
import axios from "axios";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";
import MultipleChoiceQuestionWithMoreThanOneAnswer from "./MultipleChoiceQuestionWithMoreThanOneAnswer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import UploadImage from "../Sat/renderUploadImage";
import Quantity from "./Quantity";
import TeX from "@matejmazur/react-katex";
import quantitativeReasoningStore from "../../Services/Stores/qunatitativeReasoningStore";
import MultipleChoiceQuestionWithOneAnswer from "./MultipleChoiceQuestionWithOneAnswer";
import { FaTrash } from "react-icons/fa";

const QuantitaveReasoningQuestions = observer(() => {
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ color: [] }, { background: [] }], // Text color and background color
      [{ align: [] }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "list",
    "bullet",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "color",
    "background",
    "align",
    "link",
    "image",
  ];

  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [explanationImages, setExplanationImages] = useState([]);
  const [optionImages, setOptionImages] = useState([]);
  const [questionTitleImages, setQuestionTitleImages] = useState([]);
  const [questionDescriptionImages, setQuestionDescriptionImages] = useState(
    []
  );
  let quantitativeSelectedValue = localStorage.getItem(
    "quantitativeSelectedSectionName"
  );
  const passageInputRef = useRef(null);
  const {
    payload,
    selectedSectionId,
    updatedDataGre,
    selectedSectionName,
    selectedQuestionId,
    questionTypes,
    domains,
    contentType,
  } = quantitativeReasoningStore;
  const { quantity } = payload;
  let moduleId = localStorage.getItem("moduleId");
  let filedContainer = "flex w-full items-center";
  let labelStyle = "w-2/6 flex justify-start text-lg font-medium";
  let labelContentStyle =
    "border border-gray-400 rounded-md p-2 w-full justify-start outline-none bg-white";
  const allQuestionTypes = localStorage.getItem("allQuestionTypes");
  const selectedQuantitativeQuestionType = JSON.parse(
    localStorage.getItem("selectedQuestionTypeName")
  );

  const getQuestionsById = async () => {
    try {
      const url = `${serviceUrl}/api/gre/questions/getbyid?questionId=${id}`;
      const res = await axios.get(url, {
        headers: genericHeaders(),
      });
      const quesType = questionTypes?.find(
        (item) => res.data.data.questionTypeId === item._id
      );
      const questionType = JSON.parse(allQuestionTypes);
      const questionTypeFilter = questionType.find(
        (item) => item._id === res.data.data.questionTypeId
      );
      localStorage.setItem(
        "selectedQuestionTypeName",
        JSON.stringify({
          id: questionTypeFilter._id,
          name: questionTypeFilter.name,
        })
      );
      quantitativeReasoningStore.setSelectedQuantitativeQuestionType({
        id: quesType._id,
        name: quesType.name,
      });

      const answersWithIds = res.data.data.blanks[0].answers.map(
        (answer, i) => ({
          ...answer,
          id: i + 1,
        })
      );

      quantitativeReasoningStore.setPayload({
        ...res.data.data,
        blanks: [
          {
            ...payload.blanks[0],
            answers: answersWithIds,
          },
        ],
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getDomain = async () => {
    try {
      const url = `${serviceUrl}/api/gre/questioncontentdomain/getAll`;
      const res = await axios.get(url, {
        headers: genericHeaders(),
      });
      quantitativeReasoningStore.setDomains(res.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getContentType = async () => {
    try {
      const url = `${serviceUrl}/api/gre/questioncontenttype/getAll`;
      const res = await axios.get(url, {
        headers: genericHeaders(),
      });
      quantitativeReasoningStore.setContentType(res.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getDomain();
    getContentType();
    if (id) {
      getQuestionsById();
    }
  }, [id]);

  const addQuantity = () => {
    const newQuantity = {
      id: quantity?.length + 1,
      qunatityName: "",
      qunantityDesc: "",
    };
    quantitativeReasoningStore.setPayload({
      ...payload,
      quantity: [...quantity, newQuantity],
    });
  };

  const addOption = () => {
    const newOption = {
      id: payload?.blanks?.[0]?.answers?.length + 1,
      value: "",
      isActive: true,
      answerOption: `Option ${String.fromCharCode(
        payload?.blanks?.[0]?.answers?.length + 65
      )}`,
      isCorrectAnswer: false,
      correctAnswer: "",
      answerPlaceHolder: "",
    };
    quantitativeReasoningStore.setPayload({
      ...payload,
      blanks: [
        {
          ...payload.blanks[0],
          answers: [...payload.blanks[0].answers, newOption],
        },
      ],
    });
  };

  const verbalReasoningValuesChange = async (e) => {
    const { name, value } = e.target;

    if (e.target.name === "question") {
      quantitativeReasoningStore.setPayload((prevQuestion) => ({
        ...prevQuestion,
        question: e.target.value,
      }));
    }
    if (e.target.name === "passage") {
      quantitativeReasoningStore.setPayload((prevQuestion) => ({
        ...prevQuestion,
        passage: e.target.value,
      }));
    }
    if (e.target.name === "explanation") {
      quantitativeReasoningStore.setPayload((prevQuestion) => ({
        ...prevQuestion,
        explanation: e.target.value,
      }));
    }
    if (e.target.name === "questionDifficulty") {
      quantitativeReasoningStore.setPayload((prevQuestion) => ({
        ...prevQuestion,
        questionDifficultyLevel: e.target.value,
      }));
    }
    if (e.target.name === "instructions") {
      quantitativeReasoningStore.setPayload((prevQuestion) => ({
        ...prevQuestion,
        instructions: e.target.value,
      }));
    }
    if (name === "selectInPassageAnswer") {
      quantitativeReasoningStore.setPayload((prevQuestion) => ({
        ...prevQuestion,
        selectInPassageAnswer: e.target.value,
      }));
    }

    quantitativeReasoningStore.setPayload({ ...payload, [name]: value });
  };
  const imagesArray = (questionTitle, existingImages, imageDetails) => {
    const regex = /{#([^}]+)#}/g;
    const resultArray = [];
    let match;

    while ((match = regex.exec(questionTitle)) !== null) {
      const key = match[1];
      const existingImage = existingImages.find(
        (img) => Object.keys(img)[0] === key
      );
      if (existingImage) {
        resultArray.push(existingImage);
      } else {
        const obj = {};
        if (Object.keys(imageDetails).length > 0) {
          obj[key] = imageDetails[key];
          if (imageDetails[key]) {
            obj["isEdit"] = false;
            obj["isView"] = true;
          }
        } else {
          obj[key] = "";
        }
        resultArray.push(obj);
      }
    }
    return resultArray;
  };

  const imagesArrayForAnswers = (answers, existingImages, imageDetails) => {
    let resultArray = [];
    let match;
    const regex = /{#([^}]+)#}/g;
    answers.forEach((answer) => {
      const questionTitle = answer.answerPlaceHolder.toString();
      while ((match = regex.exec(questionTitle)) !== null) {
        const key = match[1];
        const existingImage = existingImages?.find(
          (img) => Object.keys(img)[0] === key
        );
        if (existingImage) {
          resultArray.push(existingImage);
        } else {
          const obj = {};
          if (Object.keys(imageDetails).length > 0) {
            obj[key] = imageDetails[key];
            if (imageDetails[key]) {
              obj["isEdit"] = false;
              obj["isView"] = true;
            }
          } else {
            obj[key] = "";
          }
          resultArray.push(obj);
        }
      }
    });
    return resultArray;
  };

  useEffect(() => {
    const questionTitle = payload.question;
    const questionDescription = payload.description;
    const images=payload.images;
    const resultArrayTitleImages = imagesArray(
      questionTitle,
      questionTitleImages,
      images
    );
    const resultArrayDescriptionImages = imagesArray(
      questionDescription,
      questionDescriptionImages,
      images
    );

    const resultArrayExplanationImages = imagesArray(
      payload.explanation,
      explanationImages,
      images
    );
    const resultArrayOptionImages = imagesArrayForAnswers(
      payload.blanks[0].answers,
      optionImages,
      images,
    );

    setOptionImages(resultArrayOptionImages);
    setExplanationImages(resultArrayExplanationImages);
    setQuestionTitleImages(resultArrayTitleImages);
    setQuestionDescriptionImages(resultArrayDescriptionImages);
  }, [payload]);

  useEffect(() => {
    if (id) {
      const editedData = updatedDataGre.map((item) => item);
      quantitativeReasoningStore.setPayload(editedData);
    }
  }, []);
  useEffect(() => {
    quantitativeReasoningStore.setPayload({
      ...payload,
      sectionId: selectedSectionId || quantitativeSelectedValue?._id,
      questionTypeId: selectedQuantitativeQuestionType?.id,
      moduleId: moduleId,
    });
  }, []);

  const submitData = async () => {
    try {
      setLoading(true);
      questionTitleImages.forEach((itm) => {
        delete itm.isEdit;
        delete itm.isView;
        payload.images = Object.assign(payload.images || {}, itm);
      });
      questionDescriptionImages.forEach((itm) => {
        delete itm.isEdit;
        delete itm.isView;
        payload.images = Object.assign(payload.images || {}, itm);
      });

      optionImages.forEach((itm) => {
        delete itm.isEdit;
        delete itm.isView;
        payload.images = Object.assign(payload.images || {}, itm);
      });
      explanationImages.forEach((itm) => {
        delete itm.isEdit;
        delete itm.isView;
        payload.images = Object.assign(payload.images || {}, itm);
      });

      let data = { ...payload };
      data.notes = data?.notes?.filter((note) => note.trim() !== "");

      const url = `${
        id
          ? `${serviceUrl}/api/gre/questions/update?questionId=${id}`
          : `${serviceUrl}/api/gre/questions/create`
      }`;

      const response = await axios[id ? "put" : "post"](url, data, {
        headers: genericHeaders(),
      });

      if (response.status === 201 || response.status === 200) {
        quantitativeReasoningStore.setSelectedQuantitativeQuestionType("");
        quantitativeReasoningStore.setCurrentsectionId("");
        setLoading(false);
        toast.success("Saved Successfully");
        navigate("/quantitativeReasoning");
        quantitativeReasoningStore.setSelectedSectionName("");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleOptionChange = (e, id, val, index) => {
    if (e.target.name === "optionText") {
      quantitativeReasoningStore.setPayload({
        ...payload,
        blanks: [
          {
            ...payload.blanks[0],
            answers: payload.blanks[0].answers.map((option) =>
              option.id === id
                ? {
                    ...option,
                    answerPlaceHolder: e.target.value,
                  }
                : option
            ),
          },
        ],
      });
    } else if (e.target.name === "radio") {
      quantitativeReasoningStore.setPayload({
        ...payload,
        blanks: [
          {
            ...payload.blanks[0],
            answers: payload.blanks[0].answers.map((option) =>
              option.id === id
                ? { ...option, isCorrectAnswer: e.target.checked }
                : { ...option, isCorrectAnswer: false }
            ),
          },
        ],
      });
    } else if (e.target.name === "checkbox") {
      quantitativeReasoningStore.setPayload({
        ...payload,
        blanks: [
          {
            ...payload.blanks[0],
            answers: payload.blanks[0].answers.map((option) => {
              // Check if the option id matches the clicked checkbox id
              if (option.id === id) {
                // Update the isCorrectAnswer property based on checkbox state
                return {
                  ...option,
                  isCorrectAnswer: e.target.checked,
                };
              }
              // Return the option unchanged if it doesn't match
              return option;
            }),
          },
        ],
      });
    }
  };

  const handleDeleteOption = (id) => {
    const filteredOptions = payload.blanks[0].answers.filter(
      (option) => option.id !== id
    );
    const updatedOptions = filteredOptions.map((option, index) => ({
      ...option,
      answerOption: `Option ${String.fromCharCode(index + 65)}`,
    }));

    quantitativeReasoningStore.setPayload({
      ...payload,
      blanks: [
        {
          ...payload.blanks[0],
          answers: updatedOptions,
        },
      ],
    });
  };

  function countOptionImages(data, inputNumber) {
    const optionImagesPattern = new RegExp(`OptionImage${inputNumber}_\\d+`, 'i');
    console.log(data,"inside countOptionImage");
    let optionImagesObjects = [];
    data.forEach(item => {
        Object.keys(item).forEach(key => {
            if (optionImagesPattern.test(key)) {
              optionImagesObjects.push(item);
            }
        });
    });
    return  optionImagesObjects ;
}

  const addImageForOption = (id, name, index) => {
    const tempQuestion = name + `{#OptionImage${id}_${countOptionImages(optionImages,id).length + 1}#}`;
    const updatedOptions = payload.blanks[0].answers.map((option) => {
      if (option.id == id) {
        return {
          ...option,
          answerPlaceHolder: tempQuestion,
        };
      } else {
        return option;
      }
    });

    const tempQuestions = {
      ...payload,
      blanks: [
        {
          ...payload.blanks[0],
          answers: updatedOptions,
        },
      ],
    };
    quantitativeReasoningStore.setPayload(tempQuestions);
  };

  const addImage = (name) => {
    let tempQuestion;
    let tempQuestions;

    if (name === "explanation") {
      tempQuestion =
        payload.explanation +
        `{#ExplanationImage${explanationImages.length + 1}#}`;
      tempQuestions = { ...payload, explanation: tempQuestion };
    }
    if (name === "question") {
      tempQuestion =
        payload.question +
        `{#QuestionTitleImage${questionTitleImages.length + 1}#}`;
      tempQuestions = { ...payload, question: tempQuestion };
    }
    if (name === "description") {
      tempQuestion =
        payload.description +
        `{#QuestionDescriptionImage${questionDescriptionImages.length + 1}#}`;
      tempQuestions = { ...payload, description: tempQuestion };
    }
    quantitativeReasoningStore.setPayload(tempQuestions);
  };
  const handleQuillChange = (value, name) => {
    verbalReasoningValuesChange({ target: { name, value } });
  };

  const addBlank = (key) => {
    const tempQuestion = payload.question + `___________`;
    const tempQuestions = { ...payload, question: tempQuestion };

    quantitativeReasoningStore.setPayload(tempQuestions);

    // quantitativeReasoningStore.setPayload((prevPayload) => ({
    //   ...prevPayload,
    //   [key]: prevPayload[key] + " ___________ ",
    // }));
  };

  const handleQuantityChange = (e, id) => {
    const { name, value } = e.target;
    quantitativeReasoningStore.setPayload({
      ...payload,
      quantity: quantity.map((quantity) =>
        quantity.id === id
          ? {
              ...quantity,
              [name]: value,
            }
          : quantity
      ),
    });
  };

  const handleDeleteQuantity = (id) => {
    const filteredOptions = quantity.filter((qua) => qua.id !== id);
    quantitativeReasoningStore.setPayload({
      ...payload,
      quantity: filteredOptions,
    });
  };

  const renderHtmlWithTeX = (question) => {
    if (question) {
      let matchesText = question?.split("$~");
      if (matchesText && matchesText.length > 0) {
        return matchesText.map((item, index) => {
          if (index % 2 === 1) {
            return <TeX key={index}>{item}</TeX>;
          }
          return (
            <p key={index} className="mx-2">
              {item}
            </p>
          );
        });
      }
    }
  };

  const addNote = () => {
    quantitativeReasoningStore.setPayload({
      ...payload,
      notes: [...payload.notes, ""],
    });
  };

  const handleNoteChange = (e, index) => {
    const { value } = e.target;
    const newNotes = [...payload.notes];
    newNotes[index] = value;
    quantitativeReasoningStore.setPayload({
      ...payload,
      notes: newNotes,
    });
  };

  const handleDeleteNote = (index) => {
    const newNotes = payload.notes.filter((_, i) => i !== index);
    quantitativeReasoningStore.setPayload({
      ...payload,
      notes: newNotes,
    });
  };

  const getIsDisabled = () => {
    const checkForIsCorrectAnswer = () => {
      const hasCorrectAnswer = payload?.blanks[0]?.answers?.some(
        (option) => option?.isCorrectAnswer
      );
      return !hasCorrectAnswer;
    };

    const checkOptionIsEmpty = () => {
      const optionIsempty = payload?.blanks[0]?.answers?.every(
        (option) => option?.answerPlaceHolder.trim() !== ""
      );
      return !optionIsempty;
    };

    return (
      payload?.question?.trim() === "" ||
      checkForIsCorrectAnswer() ||
      checkOptionIsEmpty()
    );
  };

  return (
    <div className="mx-auto w-full lg:w-[80%] min-h-screen">
      <div className="mt-20">
        <div>
          <button
            className="hover:bg-slate-500 hover:text-white rounded-md flex items-center bg-gray-300 py-2 px-6 mb-4"
            onClick={() => {
              quantitativeReasoningStore.setResetPayload();
              navigate("/quantitativeReasoning");
            }}
          >
            <IoMdArrowBack /> <span className="ml-1">Back</span>
          </button>
        </div>
        <div className="flex text-lg pt-2">
          {"Quantitave Reasoning"}{" "}
          <span className="flex items-center">
            <IoIosArrowForward />{" "}
          </span>
          {selectedSectionName || quantitativeSelectedValue?.name}{" "}
          <span className="flex items-center">
            <IoIosArrowForward />{" "}
          </span>{" "}
          {selectedQuantitativeQuestionType?.name}
        </div>
        {selectedQuantitativeQuestionType?.name !== "Text Completion" && (
          <div className="flex flex-col mt-4 gap-6">
            <div className={filedContainer}>
              <label className={labelStyle}>Instruction</label>
              <textarea
                className={labelContentStyle}
                placeholder="Instruction"
                name="instructions"
                rows={1}
                columns={10}
                onChange={(e) => {
                  verbalReasoningValuesChange(e);
                }}
                value={payload?.instructions}
              />
            </div>
            <div className={filedContainer}>
              <label className={labelStyle}>Passage</label>
              <textarea
                className={labelContentStyle}
                placeholder="Passage"
                ref={passageInputRef}
                name="passage"
                rows={7}
                columns={10}
                onChange={(e) => {
                  verbalReasoningValuesChange(e);
                }}
                value={payload?.passage}
              />
            </div>
            <div className="flex justify-between items-center">
              <label className={labelStyle}>
                Question<span className="text-[#ffa500] text-xl">*</span>
              </label>
              <button
                className="border border-[#ffa500] text-[#ffa500] w-40 rounded-full my-2 px-4 py-1 ml-4"
                onClick={() => addImage("question")}
              >
                Add Image
              </button>
              <button
                className="border border-[#ffa500] text-[#ffa500] w-40 rounded-full my-2 px-4 py-1 ml-4"
                onClick={() => addBlank("question")}
              >
                Add Blank
              </button>
            </div>

            <div className={filedContainer}>
              <textarea
                className="w-full p-4 outline-none  min-h-56 border bg-white border-gray-200 rounded-lg text-sm focus:border-[#ffa500] focus:ring-[#ffa500] disabled:opacity-50 disabled:pointer-events-none"
                rows="2"
                cols="50"
                name="question"
                onChange={(e) => {
                  verbalReasoningValuesChange(e);
                }}
                value={payload?.question || ""}
              />{" "}
              {/* <ReactQuill
                className={labelContentStyle}
                placeholder="Question"
                value={payload?.question || ""}
                onChange={(value) => handleQuillChange(value, "question")}
                modules={modules}
                formats={formats}
              /> */}
            </div>
            {payload?.question && (
              <div className="border border-gray-400 px-5 rounded-md">
                <div className="flex flex-col p-2">
                  <span className="flex my-2">
                    <div className="flex">
                      {renderHtmlWithTeX(payload.question)}{" "}
                    </div>
                  </span>
                </div>
              </div>
            )}
            {questionTitleImages?.map((item, index) => {
              return (
                <>
                  <UploadImage
                    indexTitleImage={index}
                    item={item}
                    questionTitleImages={questionTitleImages}
                    setQuestionTitleImages={setQuestionTitleImages}
                  />
                </>
              );
            })}
            {selectedQuantitativeQuestionType?.name ===
              "Quantitative Comparison" && (
              <div className="flex flex-col">
                <button
                  onClick={addQuantity}
                  className="border border-[#ffa500] text-[#ffa500] rounded-full my-2 px-4 py-1 w-40"
                >
                  Add Quantity
                </button>

                {payload?.quantity?.length > 0 &&
                  payload?.quantity.map((qua, index) => (
                    <>
                      <p className="mt-2">Quantity {index + 1}</p>
                      <Quantity
                        key={qua.id}
                        quantity={qua}
                        handleQuantityChange={(e) =>
                          handleQuantityChange(e, qua.id, e.target.value)
                        }
                        handleDeleteQuantity={() =>
                          handleDeleteQuantity(qua.id)
                        }
                      />
                      {qua.qunantityDesc && (
                        <div className="border border-gray-400 px-5 rounded-md my-6">
                          <div className="flex flex-col p-2">
                            <span className="flex my-2">
                              <div className="flex">
                                {renderHtmlWithTeX(qua.qunantityDesc)}{" "}
                              </div>
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </div>
            )}

            <div>
              <button
                onClick={addOption}
                className="border border-[#ffa500] text-[#ffa500] rounded-full my-2 px-4 py-1 mr-4 w-40"
              >
                Add Option
              </button>
              {payload?.blanks?.[0]?.answers?.length > 0 &&
                payload.blanks[0].answers.map((option, index) => (
                  <>
                    {selectedQuantitativeQuestionType?.name ===
                    "Multiple choice questions with more than one answer" ? (
                      <>
                        <button
                          className="border border-[#ffa500] text-[#ffa500] w-40 rounded-full my-2 px-4 py-1 ml-4"
                          onClick={() =>
                            addImageForOption(
                              option.id,
                              option.answerPlaceHolder,
                              index
                            )
                          }
                        >
                          Add Image
                        </button>
                        <MultipleChoiceQuestionWithMoreThanOneAnswer
                          key={option.id}
                          option={option}
                          handleOptionChange={(e) =>
                            handleOptionChange(
                              e,
                              option.id,
                              e.target.value,
                              index
                            )
                          }
                          handleDeleteOption={() =>
                            handleDeleteOption(option.id)
                          }
                          renderOfImageOptions={countOptionImages(optionImages,option.id)}
                          questionTitleImages={optionImages}
                          setQuestionTitleImages={setOptionImages}
                        />
                      </>
                    ) : (
                      <>
                        <MultipleChoiceQuestionWithOneAnswer
                          key={option.id}
                          option={option}
                          handleOptionChange={(e) =>
                            handleOptionChange(
                              e,
                              option.id,
                              e.target.value,
                              index
                            )
                          }
                          handleDeleteOption={() =>
                            handleDeleteOption(option.id)
                          }
                          renderOfImageOptions={countOptionImages(optionImages,option.id)}
                          questionTitleImages={optionImages}
                          setQuestionTitleImages={setOptionImages}
                        />
                        <button
                          className="border border-[#ffa500] text-[#ffa500] w-40 rounded-full my-2 px-4 py-1 ml-4"
                          onClick={() =>
                            addImageForOption(
                              option.id,
                              option.answerPlaceHolder,
                              index
                            )
                          }
                        >
                          Add Image
                        </button>
                      </>
                    )}
                    {option.answerPlaceHolder && (
                      <div className="border border-gray-400 px-5 rounded-md my-6">
                        <div className="flex flex-col p-2">
                          <span className="flex my-2">
                            <div className="flex">
                              {renderHtmlWithTeX(option.answerPlaceHolder)}{" "}
                            </div>
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                ))}
            </div>

            <div className="flex flex-col">
              <button
                onClick={addNote}
                className="border border-[#ffa500] text-[#ffa500] rounded-full my-2 px-4 py-1 w-40"
              >
                Add Notes
              </button>

              {payload?.notes?.length > 0 &&
                payload?.notes.map((note, index) => (
                  <>
                    <p className="mt-2">Note {index + 1}</p>
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        name="note"
                        placeholder="Enter Note"
                        value={note}
                        onChange={(e) => handleNoteChange(e, index)}
                        className="py-3 w-1/2 px-4  border bg-white border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      />
                      <FaTrash
                        onClick={() => handleDeleteNote(index)}
                        className="text-red-600 cursor-pointer ml-4"
                      />
                    </div>
                  </>
                ))}
            </div>

            <div className="flex justify-between items-center">
              <label className="text-[#4E5664] py-1">
                Question Description:
              </label>
              <button
                className="border border-[#ffa500] text-[#ffa500] w-40 rounded-full my-2 px-4 py-1 ml-4"
                onClick={() => addImage("description")}
              >
                Add Image
              </button>
            </div>
            <div className="flex justify-between">
              <textarea
                className="w-full p-4 outline-none  min-h-56 border bg-white border-gray-200 rounded-lg text-sm focus:border-[#ffa500] focus:ring-[#ffa500] disabled:opacity-50 disabled:pointer-events-none"
                rows="2"
                cols="50"
                name="description"
                onChange={(e) => {
                  verbalReasoningValuesChange(e);
                }}
                value={payload.description}
              ></textarea>
            </div>
            {questionDescriptionImages?.map((item, index) => {
              return (
                <>
                  <UploadImage
                    indexTitleImage={index}
                    item={item}
                    questionTitleImages={questionDescriptionImages}
                    setQuestionTitleImages={setQuestionDescriptionImages}
                  />
                </>
              );
            })}
            <div className="my-2">
              <label className="text-[#4E5664] py-1 mr-2">
                Question Difficulty:
              </label>
              <select
                name="questionDifficultyLevel"
                value={payload.questionDifficultyLevel}
                onChange={(e) => {
                  verbalReasoningValuesChange(e);
                }}
                className="py-2 px-4 bg-white border outline-none focus:border-[#ffa500] focus:ring-[#ffa500] border-gray-200 rounded-lg text-base"
              >
                <option value="">Select Difficulty</option>
                {Array.from({ length: 10 }, (_, index) => {
                  return <option value={index + 1}>{index + 1}</option>;
                })}
              </select>
            </div>

            <div className={`flex flex-col justify-start `}>
              <div className="flex justify-between items-center">
                <label className={` text-[#4E5664]`}>Explanation</label>
                <button
                  className="border border-[#ffa500] text-[#ffa500] w-40 rounded-full my-2 px-4 py-1 ml-4"
                  onClick={() => addImage("explanation")}
                >
                  Add Image
                </button>
              </div>
              <textarea
                className={labelContentStyle}
                placeholder="Explanation"
                name="explanation"
                rows={7}
                columns={10}
                onChange={(e) => {
                  verbalReasoningValuesChange(e);
                }}
                value={payload?.explanation}
              />
              {explanationImages?.map((item, index) => {
                return (
                  <>
                    <UploadImage
                      indexTitleImage={index}
                      item={item}
                      questionTitleImages={explanationImages}
                      setQuestionTitleImages={setExplanationImages}
                    />
                  </>
                );
              })}
            </div>
            {payload?.explanation && (
              <div className="border border-gray-400 px-5 rounded-md my-6">
                <div className="flex flex-col p-2">
                  <span className="flex my-2">
                    <div className="flex">
                      {renderHtmlWithTeX(payload?.explanation)}{" "}
                    </div>
                  </span>
                </div>
              </div>
            )}
            {selectedQuantitativeQuestionType?.name ===
              "Reading Comprehension - Select-in-Passage" && (
              <>
                <label className={labelStyle}>
                  Answer<span className="text-[#ffa500] text-xl">*</span>
                </label>
                <textarea
                  className={labelContentStyle}
                  placeholder="Answer"
                  name="answer"
                  rows={7}
                  columns={10}
                  onChange={(e) => {
                    verbalReasoningValuesChange(e);
                  }}
                  value={payload?.blanks[0]?.answers}
                />
              </>
            )}
          </div>
        )}

        <div className="my-2 flex items-center">
          <label className="text-[#4E5664] py-1 mr-2">
            Select Content Domain:
          </label>
          <select
            name="questionContentDomainId"
            value={payload.questionContentDomainId}
            onChange={(e) => {
              verbalReasoningValuesChange(e);
            }}
            className="py-2 px-4 bg-white border outline-none focus:border-[#ffa500] focus:ring-[#ffa500] border-gray-200 rounded-lg text-base"
          >
            <option value="">Select Content Domain</option>
            {domains?.length > 0 &&
              domains
                .filter((x) => x.description === "Quantitative Reasoning")
                .map((item, i) => {
                  return <option value={item._id}>{item.name}</option>;
                })}
          </select>
        </div>
        {payload.questionContentDomainId && (
          <div className="my-2 flex items-center">
            <label className="text-[#4E5664] py-1 mr-2">
              Select Content Type:
            </label>
            <select
              name="questionContentTypeId"
              value={payload.questionContentTypeId}
              onChange={(e) => {
                verbalReasoningValuesChange(e);
              }}
              className="py-2 px-4 bg-white border outline-none focus:border-[#ffa500] focus:ring-[#ffa500] border-gray-200 rounded-lg text-base"
            >
              <option value="">Select Content Type</option>
              {contentType?.length > 0 &&
                contentType
                  .filter(
                    (x) => x.contentDomainId === payload.questionContentDomainId
                  )
                  .map((item, i) => {
                    return <option value={item._id}>{item.name}</option>;
                  })}
            </select>
          </div>
        )}

        <button
          className={`${
            payload?.question === "" ? "bg-gray-400" : "bg-[#ffa500]"
          } text-white rounded-md py-2 px-6 mb-4`}
          onClick={submitData}
          disabled={getIsDisabled()}
        >
          {loading ? (
            <svg
              className="animate-spin h-5 w-5 text-white mr-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
          ) : (
            <span className="ml-1">{!id ? "Save" : "Update"}</span>
          )}
        </button>
      </div>
    </div>
  );
});

export default QuantitaveReasoningQuestions;

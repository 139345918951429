import React, { useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import CustomToolTip from "../Common/CustomToolTip";
import { useNavigate } from "react-router-dom";
import { genericHeaders, serviceUrl } from "../../utils/apiConfig";
import { observer } from "mobx-react-lite";
import axios from "axios";
import writingStore from "../../Services/Stores/writingStore";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";

const TaskOne = observer(() => {
  const navigate = useNavigate();
  const { questionsPartOne, selectedSectionId } = writingStore;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const renderQuestions = async () => {
    try {
      setLoading(true);
      const url = `${serviceUrl}/api/writing/questions/fetch?sectionId=${selectedSectionId}`;
      const response = await axios.get(url, { headers: genericHeaders() });
      if (response.status === 200) {
        setLoading(false);
        let formattedData = JSON.parse(JSON.stringify(response.data));
        writingStore.setQuestionsPartOne(formattedData);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    renderQuestions();
  }, []);

  const deletePartOneQuestion = (id) => {
    setItemToDelete(id);
    setIsDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const url = `${serviceUrl}/api/writing/questions/remove?questionId=${itemToDelete}`;
      const response = await axios.delete(url, { headers: genericHeaders() });
      if (response.status === 200) {
        toast.success("Question Deleted Successfully");
        renderQuestions();
      }
    } catch (error) {
      setIsDialogOpen(false);
      setItemToDelete(null);
      toast.error("Something Went Wrong");
      console.error("Error deleting question:", error);
    } 
  };

  const cancelDelete = () => {
    setIsDialogOpen(false);
    setItemToDelete(null);
  };

  return (
    <div>
      {isDialogOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-md">
            <h2 className="text-xl mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete this item?</p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={cancelDelete}
                className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                className="bg-red-500 text-white py-2 px-4 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
          {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <RotatingLines
            visible={true}
            strokeColor="red"
            height="96"
            width="96"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : 
      <table className="mt-4">
        <thead>
          <tr className="bg-[#F8F9FA] text-[#737E93] text-sm font-semibold">
            <td>S.No</td>
            <td>Name</td>
            <td>Task</td>
            <td>Image</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {questionsPartOne && questionsPartOne.length > 0 ? (
            questionsPartOne.map((item, index) => {
              const imageUrl = item.imageUrl?.map(
                (resource) => resource.resourceName
              );
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.task}</td>
                  <td>
                    <img src={imageUrl[0]} alt="ima" />
                  </td>
                  <td>
                    <div className="flex justify-center">
                      <div>
                        <button
                          data-tooltip-id="edit-tooltip"
                          className="mr-2"
                          onClick={() => {
                            writingStore.setUpdatedData(item);
                            writingStore.savePersistedData();
                            navigate(`/createWritingPart1/${item?.id}`);
                          }}
                        >
                          <BiEdit className="text-xl" />
                        </button>
                        <CustomToolTip
                          content={"Edit"}
                          id={"edit-tooltip"}
                          place={"bottom"}
                          onClick={() => {}}
                        />
                      </div>
                      <div>
                        <button
                          data-tooltip-id="delete-tooltip"
                          className="mr-2"
                          onClick={() => {
                            deletePartOneQuestion(item?.id);
                          }}
                        >
                          <RiDeleteBin5Line className="text-red-500 text-xl" />
                        </button>
                        <CustomToolTip
                          content={"Delete"}
                          id={"delete-tooltip"}
                          place={"bottom"}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <div></div>
          )}
        </tbody>
      </table>}
    </div>
  );
});

export default TaskOne;

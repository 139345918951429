import React, { useEffect, useRef, useState } from "react";
import listeningStore from "../../Services/Stores/listeningStore";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { FaPlus } from "react-icons/fa";
import CustomToolTip from "../Common/CustomToolTip";

const AnswerTable = ({
  table,
  onUpdateCell,
  questionIndex,
  inputRef,
  tooltipMaxWidth,
}) => {
  const { formData, editMode } = listeningStore;
  console.log("Props in AnswerTable:", { table, editMode });

  if ((!table || !table.tbody || table.tbody.length === 0) && !editMode) {
    return <p>No data available.</p>;
  }

  const handleAddPlaceholder = (index, rowIndex, columnIndex) => {
    const updatedTable = { ...table };
    const existingAnswerOption =
      updatedTable.tbody[rowIndex][table.theader[columnIndex]];
    const inputElement = document.getElementById(
      `optionInput-${index}-${rowIndex}-${columnIndex}`
    );

    const cursorPosition = inputElement.selectionStart;
    const newAnswerOption =
      existingAnswerOption.slice(0, cursorPosition) +
      "{answer}" +
      existingAnswerOption.slice(cursorPosition);
    updatedTable.tbody[rowIndex][table.theader[columnIndex]] = newAnswerOption;
    let updatedFormData = [...formData.questions];
    updatedFormData[questionIndex].questionTable = updatedTable;
    listeningStore.setFormData({ ...formData, questions: updatedFormData });
  };

  return (
    <div className="overflow-x-auto max-w-full">
      <table>
        <thead>
          <tr>
            {table &&
              table.theader &&
              table.theader.length > 0 &&
              table.theader.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {table &&
            table.tbody &&
            table.tbody.length > 0 &&
            table.tbody.map((row, rowIndex) => {
              return (
                <tr key={rowIndex}>
                  {table &&
                    table.theader.map((header, columnIndex) => {
                      console.log("Header:", header);

                      let hasPlaceholder = "";
                      // hasPlaceholder = /{(\w+)}/.test(row[header]);
                      hasPlaceholder = !/{\w+}/.test(row[header]);

                      console.log(
                        toJS(row),
                        hasPlaceholder,
                        toJS(row[header]),
                        "row123"
                      );
                      return (
                        <td key={columnIndex}>
                          <div className="flex justify-between w-full px-4">
                            <input
                              ref={inputRef}
                              data-tooltip-id={`optionInput-${questionIndex}-${rowIndex}-${columnIndex}`}
                              id={`optionInput-${questionIndex}-${rowIndex}-${columnIndex}`}
                              className="border w-full border-gray-300 flex-1 py-1 px-2 bg-white"
                              type="text"
                              value={row[header]}
                              onChange={(e) =>
                                onUpdateCell(
                                  rowIndex,
                                  columnIndex,
                                  e.target.value
                                )
                              }
                            />
                            <CustomToolTip
                              place={"bottom"}
                              content={row[header]}
                              id={`optionInput-${questionIndex}-${rowIndex}-${columnIndex}`}
                              tooltipMaxWidth={tooltipMaxWidth}
                            />
                            {hasPlaceholder && (
                              <button
                                data-tooltip-id={`${rowIndex},${columnIndex}`}
                                className="inline-block ml-2"
                                onClick={() =>
                                  handleAddPlaceholder(
                                    questionIndex,
                                    rowIndex,
                                    columnIndex
                                  )
                                }
                              >
                                <FaPlus />
                              </button>
                            )}
                            <CustomToolTip
                              content={"Add Answer"}
                              id={`${rowIndex},${columnIndex}`}
                              place={"bottom"}
                            />
                          </div>
                        </td>
                      );
                    })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

const TableInputGenerator = ({ onSubmit, questionIndex }) => {
  const [numRows, setNumRows] = useState(0);
  const [numCols, setNumCols] = useState(0);
  const [headers, setHeaders] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const table = {
      theader: headers,
      tbody: Array.from({ length: numRows }, (_, rowIndex) => {
        const row = {};
        for (let colIndex = 0; colIndex < numCols; colIndex++) {
          row[headers[colIndex]] = "";
        }
        return row;
      }),
    };
    console.log("Generated Table:", table); // Log the generated table

    onSubmit(table);
  };
  return (
    <form onSubmit={handleSubmit}>
      <label className="mb-3">
        Number of Rows:
        <input
          className="border border-gray-300 ml-4 mb-2 px-3 py-1 rounded-md bg-white"
          type="number"
          value={numRows}
          onChange={(e) => setNumRows(e.target.value)}
        />
      </label>
      <br />
      <label>
        Number of Columns:
        <input
          className="border border-gray-300 ml-4 mb-2 px-3 py-1 rounded-md bg-white"
          type="number"
          value={numCols}
          onChange={(e) => setNumCols(e.target.value)}
        />
      </label>
      <br />
      <label>
        Table Headers{" "}
        <span className="font-semibold text-red-900">($-separated)</span>:
        <input
          className="border border-gray-300 ml-4 mb-2 px-3 py-1 rounded-md bg-white"
          type="text"
          value={headers.join("$")}
          onChange={(e) =>
            setHeaders(e.target.value ? e.target.value.split("$") : [])
          }
        />
      </label>
      <br />
      <button
        disabled={
          numCols === 0 ||
          numRows === 0 ||
          numCols === "" ||
          numRows === "" ||
          headers.length === 0 ||
          parseInt(numCols) !== headers.length
        }
        className={`border ${
          numCols === 0 ||
          numRows === 0 ||
          numCols === "" ||
          numRows === "" ||
          headers.length === 0 ||
          parseInt(numCols) !== headers.length
            ? "text-gray-500 border-gray-500 "
            : "text-[#E31837] border-[#E31837] "
        } rounded-full my-4 px-4 py-1`}
        type="submit"
      >
        Generate Table
      </button>
    </form>
  );
};

const ListeningQuestionTable = observer(({ questionIndex }) => {
  const [table, setTable] = useState(null);
  const { formData, editMode } = listeningStore;

  useEffect(() => {
    setTable(formData.questions[questionIndex].questionTable);
  }, [formData.questions, questionIndex]);

  const handleTableSubmit = (newTable) => {
    // setTable(newTable);
    const updatedQuestions = [...formData.questions];
    updatedQuestions[questionIndex].questionTable = newTable;
    listeningStore.setFormData({ ...formData, questions: updatedQuestions });
    setTable(newTable);
  };

  const handleUpdateCell = (rowIndex, colIndex, value) => {
    if (table) {
      const updatedTable = { ...table };
      updatedTable.tbody[rowIndex][table.theader[colIndex]] = value;
      const updatedQuestions = [...formData.questions];
      updatedQuestions[questionIndex].questionTable = updatedTable;
      listeningStore.setFormData({ ...formData, questions: updatedQuestions });
    }
  };

  const [tooltipMaxWidth, setTooltipMaxWidth] = useState("200px"); // Set a default value or adjust based on your design
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      const inputWidth = inputRef.current.offsetWidth;
      setTooltipMaxWidth(`${inputWidth}px`);
    }
  }, []);

  return (
    <div className="mt-4">
      <h1 className="text-xl mb-1">Your Table</h1>
      {table && table.tbody && table.tbody.length > 0 && editMode && (
        // Show AnswerTable when tbody exists and editMode is true
        <AnswerTable
          tooltipMaxWidth={tooltipMaxWidth}
          questionIndex={questionIndex}
          table={table}
          inputRef={inputRef}
          onUpdateCell={handleUpdateCell}
        />
      )}

      {(!table || !table.tbody || table.tbody.length === 0) && editMode && (
        // Show TableInputGenerator when tbody is missing or empty and editMode is true
        <TableInputGenerator
          questionIndex={questionIndex}
          onSubmit={handleTableSubmit}
        />
      )}

      {!editMode && table && table.tbody && table.tbody.length > 0 && (
        // Show AnswerTable when tbody exists and editMode is false
        <AnswerTable
          tooltipMaxWidth={tooltipMaxWidth}
          questionIndex={questionIndex}
          table={table}
          inputRef={inputRef}
          onUpdateCell={handleUpdateCell}
        />
      )}

      {!editMode && table && !table.tbody && (
        // Show AnswerTable when tbody exists and editMode is false
        <TableInputGenerator
          questionIndex={questionIndex}
          onSubmit={handleTableSubmit}
        />
      )}
    </div>
  );
});

export default ListeningQuestionTable;

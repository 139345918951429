// App.js
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import ReadingMain from "./Pages/ReadingMain";
import Sidebar from "./Components/Sidebar";
import Reading from "./Components/Reading";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import NotFoundPage from "./Pages/NotFoundPage";
import LoginPage from "./Pages/LoginPage";
import SignupPage from "./Pages/SignupPage";
import CustomHeader from "./Components/Common/CustomHeader";
import { getToken } from "./Services/token";
import ListeningMain from "./Pages/ListeningMain";
import Listening from "./Components/Listening";
import { Buffer } from "buffer";
import GenerativeAi from "./Components/Generative AI/generativeAi";
import SatReading from "./Pages/SatReading";
import SatMaths from "./Pages/SatMaths";
import ReadingQuestionsSAT from "./Pages/ReadingQuestionsSAT";
import MultipleChoiceQuestion from "./Components/Sat/multipleChoiceQuestion";
import ViewMathQuestion from "./Pages/ViewMathQuestion";
import Writing from "./Pages/WritingMain";
import CreateWritingPart1 from "./Pages/Writing/CreateWritingPart1";
import CreateWritingPart2 from "./Pages/Writing/CreateWritingPart2";
import VerbalReasoning from "./Components/GRE/verbalReasoning";
import AnalyticalWriting from "./Components/GRE/analyticalWriting";
import QuantitativeReasoning from "./Components/GRE/quantitativeReasoning";
import AnalyticalWritingQuestions from "./Components/GRE/analyticalWritingQuestions";
import VerbalReasoningQuestions from "./Components/GRE/verbalReasoningQuestions";
import QuantitaveReasoningQuestions from "./Components/GRE/QuantitaveReasoningQuestions";

function AuthenticatedLayout({ children }) {
  const [open, setOpen] = useState(true);

  const navigate = useNavigate();
  let token = getToken("token");

  useEffect(() => {
    console.log("Checking authentication...");
    const isAuthenticated = token != null;
    if (!isAuthenticated) {
      navigate("/");
    } else {
      console.log("User authenticated, navigating to /dashboard");
    }
  }, [token, navigate]);

  return (
    <div>
      <CustomHeader />
      <div className="flex  bg-gray-100">
        <div className="fixed h-screen bg-gray-800">
          <Sidebar setOpen={setOpen} open={open} />
        </div>{" "}
        <div
          className={`flex-1 flex flex-col overflow-x-hidden ${
            open ? "ml-72" : "ml-10"
          } `}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

function App() {
  window.Buffer = Buffer;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route
          path="/dashboard"
          element={
            <AuthenticatedLayout>
              <ReadingMain />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/reading"
          element={
            <AuthenticatedLayout>
              <Reading />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/reading/:id"
          element={
            <AuthenticatedLayout>
              <Reading />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/createlisteningpassage"
          element={
            <AuthenticatedLayout>
              <Listening />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/listening"
          element={
            <AuthenticatedLayout>
              <ListeningMain />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/writing"
          element={
            <AuthenticatedLayout>
              <Writing />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/createWritingPart1"
          element={
            <AuthenticatedLayout>
              <CreateWritingPart1 />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/quantitativeReasoning"
          element={
            <AuthenticatedLayout>
              <QuantitativeReasoning />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/analyticalWriting"
          element={
            <AuthenticatedLayout>
              <AnalyticalWriting />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/verbalReasoning"
          element={
            <AuthenticatedLayout>
              <VerbalReasoning />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/createWritingPart1/:id"
          element={
            <AuthenticatedLayout>
              <CreateWritingPart1 />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/createWritingPart2"
          element={
            <AuthenticatedLayout>
              <CreateWritingPart2 />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/createWritingPart2/:id"
          element={
            <AuthenticatedLayout>
              <CreateWritingPart2 />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/generativeAI"
          element={
            <AuthenticatedLayout>
              <GenerativeAi />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/listening/:id"
          element={
            <AuthenticatedLayout>
              <Listening />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/satReading"
          element={
            <AuthenticatedLayout>
              <SatReading />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/satMaths"
          element={
            <AuthenticatedLayout>
              <SatReading />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/viewQuestion/:id"
          element={
            <AuthenticatedLayout>
              <ViewMathQuestion />
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/satReadingQuestions"
          element={
            <AuthenticatedLayout>
              {/* <ReadingQuestionsSAT /> */}
              <div className="flex flex-col h-full mx-auto w-[80%] mt-10 p-8">
                {/* {questionType ==='Multiple choice-Single Answer' ? <MultipleChoiceQuestion /> : <ShortForm />} */}
                <MultipleChoiceQuestion />
              </div>
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/analyticalWritingQuestions"
          element={
            <AuthenticatedLayout>
              <div className="flex flex-col h-full mx-auto w-[80%] mt-10 p-8">
                <AnalyticalWritingQuestions />
              </div>
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/quantitaveReasoningQuestions"
          element={
            <AuthenticatedLayout>
              <div className="flex flex-col h-full mx-auto xl:w-[80%] w-full mt-10 p-8">
                <QuantitaveReasoningQuestions />
              </div>
            </AuthenticatedLayout>
          }
        />
         <Route
          path="/quantitaveReasoningQuestions/:id"
          element={
            <AuthenticatedLayout>
              <div className="flex flex-col h-full mx-auto xl:w-[80%] w-full mt-10 p-8">
                <QuantitaveReasoningQuestions />
              </div>
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/verbalReasoningQuestions"
          element={
            <AuthenticatedLayout>
              <div className="flex flex-col h-full mx-auto w-[80%] mt-10 p-8">
                <VerbalReasoningQuestions />
              </div>
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/verbalReasoningQuestions/:id"
          element={
            <AuthenticatedLayout>
              <div className="flex flex-col h-full mx-auto w-[80%] mt-10 p-8">
                <VerbalReasoningQuestions />
              </div>
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/analyticalWritingQuestions/:id"
          element={
            <AuthenticatedLayout>
              <div className="flex flex-col h-full mx-auto w-[80%] mt-10 p-8">
                <AnalyticalWritingQuestions />
              </div>
            </AuthenticatedLayout>
          }
        />
        <Route
          path="/satReadingQuestions/:id"
          element={
            <AuthenticatedLayout>
              {/* <ReadingQuestionsSAT /> */}
              <div className="flex flex-col h-full mx-auto w-[80%] mt-10 p-8">
                {/* {questionType ==='Multiple choice-Single Answer' ? <MultipleChoiceQuestion /> : <ShortForm />} */}
                <MultipleChoiceQuestion />
              </div>
            </AuthenticatedLayout>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
